<template lang="html" src="./Roles.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import { COMMA_SEPARATOR } from "@/constants";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";

import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";

import dateMixin from "@/mixins/date.mixin";
import tableFrontMixin from "@/mixins/table.mixin/front";
import filterMixin from "@/mixins/filter.mixin";

import RolesTable from "./RolesTable/RolesTable.vue";
import ApiRoles from "@/api/roles";

const keyView = "Roles";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";

const FILTER_DEFINITIONS = {
  email: {
    literal: "user.email",
  },
  rol: {
    literal: "internalusers.rol",
  },
};

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    AppChipInfo,
    FilterTableHeader,
    AppFilterButtons,
    AppKpiChipInfo,
    AppInputDate,
    ExportTableDataButton,
    RolesTable,
  },
  mixins: [dateMixin, tableFrontMixin, filterMixin],
})
export default class Roles extends Vue {
  filterOptions!: any;
  filterOptions_!: any;
  filterContains!: Function;
  saveFilters!: Function;
  checkHasAnyActiveFilter!: Function;

  COMMA_SEPARATOR = COMMA_SEPARATOR;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  filterRol = "";
  number = 0;
  loadingData = false;
  tableProps: any = {
    sortBy: ["id"],
  };

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };

    return this.checkHasAnyActiveFilter(filters);
  }

  async created() {
    ApiRoles.getRoles().then((response) => {
      this.number = response.length;
    });
  }

  onFilterSubmit(e) {
    e.preventDefault();

    this.saveFilters();

    this.tableProps.page = 1;

    this.refreshList();
  }

  refreshList() {
    this.saveFilters();

    this.loadingData = false;
  }

  filterRols(item, filters) {
    if (filters.rol) {
      const show = this.filterContains(item.rol, filters.rol);

      if (!show) return false;
    }

    return true;
  }

  colorStatus() {
    return {
      TRUE: "blue",
      FALSE: "grey darken-2",
    };
  }
}
</script>
