import { AxiosHelper } from "../helpers/AxiosHelper";
import PhoneInfoInterface from "./PhoneInfoInterface";

export default class UserService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/user/api/v1";
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    const url = this.urlBase + "/userid?email=" + encodeURIComponent(email);
    return this.axiosService.get(url);
  }

  public async getPhoneInfo(userId: string): Promise<PhoneInfoInterface[]> {
    const url =
      process.env.VUE_APP_PRE_URI +
      "/notification/api/v1/apptoken?userId=" +
      userId +
      "&page=0&size=2000&sort=updateDate,desc&active=true";
    return this.axiosService.get(url).then((response) => {
      return response.content;
    });
  }
}
