import {
  PairingLogBaseInterface,
  PairingLogDeviceInterface,
} from "./PairingInterface";
import PairingLogBaseEntity from "./PairingLogBaseEntity";

export default class PairingLogDeviceEntity extends PairingLogBaseEntity {
  public email: string;
  public actionT: string;
  private master: boolean;
  public reason: string | undefined;

  public constructor(data: PairingLogDeviceInterface) {
    const pairingLog: PairingLogBaseInterface = {
      time: data.time,
      type: data.type,
      subtype: data.subtype,
      requestedBy: data.requestedBy,
      guestType: data.guestType,
    };
    super(pairingLog);
    this.email = data.email;
    this.master = data.master;
    this.actionT = this.generateActionT();
    try {
      this.reason = JSON.parse(data.data).reason;
    } catch (error) {
      this.reason = undefined;
    }
  }

  /** Genera el código del texto de la acción para traducirlo. */
  private generateActionT(): string {
    if (this.isPaired()) {
      if (this.isMaster()) {
        return "deviceDetails.losSupport.actions.masterPairing";
      } else if (this.isSupport()) {
        return "deviceDetails.losSupport.actions.supportPairing";
      } else {
        return "deviceDetails.losSupport.actions.guestPairing";
      }
    }
    if (this.isUnpaired()) {
      if (this.isMaster()) {
        return "deviceDetails.losSupport.actions.masterUnpairing";
      } else if (this.isSupport()) {
        return "deviceDetails.losSupport.actions.supportUnpairing";
      } else {
        return "deviceDetails.losSupport.actions.guestUnpairing";
      }
    }
    return "";
  }

  /** Es un master. */
  private isMaster(): boolean {
    return this.master;
  }
}
