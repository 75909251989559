import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";

export default class ExportRoles<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.titleRoles"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Correo electrónico.
    this.exportTable.addHeader({
      key: "rol",
      label: t("roles.itemName"),
    });

    this.exportTable.addHeader({
      key: "details[0]",
      label: t("menu.dashboard"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[1]",
      label: t("menu.plans"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[2]",
      label: t("menu.installations"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[3]",
      label: t("menu.devices"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[4]",
      label: t("menu.users"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[5]",
      label: t("menu.jobs"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[6]",
      label: t("menu.firmware"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[7]",
      label: t("menu.oauthclients"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[8]",
      label: t("menu.internalUsers"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[9]",
      label: t("menu.titleRoles"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[10]",
      label: t("menu.titleManufactur"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
    this.exportTable.addHeader({
      key: "details[11]",
      label: t("menu.titleCall"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
  }
}
