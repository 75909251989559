import { Cell as CellPdf } from "jspdf-autotable";
import ExportHeaderInterface, { ExportType } from "./ExportHeaderInterface";
import ExportTableExcel from "./ExportTableExcel";
import ExportTablePDF from "./ExportTablePDF";
import ExportCell from "./ExportCell";
import { Cell as CellExcel } from "exceljs";
import ExportTableCSV from "./ExportTableCSV";

export default class ExportTable<T> {
  /** Título del documento. */
  private title: string;
  /** Cabeceras. */
  private headers: ExportHeaderInterface<T>[];
  /** Datos. */
  private data: T[];
  constructor(title: string) {
    this.title = title;
    this.headers = [];
    this.data = [];
  }

  /**
   * Obtiene el nombre del fichero a partir del título del documento.
   * Elimina caracteres no permitidos y reemplaza espacios por guiones bajos.
   */
  private getFileName(): string {
    return this.title
      .toLowerCase()
      .replace(/[^a-z0-9.]/gi, "_")
      .replace(/_+/g, "_");
  }

  /** Añade una cabecera a la tabla. */
  public addHeader(header: ExportHeaderInterface<T>): void {
    this.headers.push(header);
  }

  /** Añade una fila a la tabla. */
  public addData(data: T[]): void {
    this.data.push(...data);
  }

  /** Descarga un archivo, con los datos, en formato Excel. */
  private downloadExcel(): void {
    const exporTableExcel = new ExportTableExcel(
      this.title,
      this.callbackStylesExcelPdf.bind(this)
    );
    this.headers.forEach((header) => {
      exporTableExcel.addHeader(header.label, header.width);
    });
    this.data.forEach((datum) => {
      const data: string[] = [];
      this.headers.forEach((header) => {
        data.push(this.getValueString(header, datum));
      });
      exporTableExcel.addDatum(data);
    });
    exporTableExcel.download(this.getFileName());
  }

  /** Descarga un archivo, con los datos, en formato PDF. */
  private downloadPdf(): void {
    const exporTablePdf = new ExportTablePDF(
      this.title,
      this.callbackStylesExcelPdf.bind(this)
    );
    this.headers.forEach((header) => {
      exporTablePdf.addHeader(header.label);
    });
    this.data.forEach((datum) => {
      const data: string[] = [];
      this.headers.forEach((header) => {
        data.push(this.getValueString(header, datum));
      });
      exporTablePdf.addDatum(data);
    });
    exporTablePdf.download(this.getFileName());
  }

  /** Descarga un archivo, con los datos, en formato CSV. */
  private downloadCsv(): void {
    const exporTableCsv = new ExportTableCSV(this.title);
    this.headers.forEach((header) => {
      exporTableCsv.addHeader(header.label);
    });
    this.data.forEach((datum) => {
      const data: string[] = [];
      this.headers.forEach((header) => {
        data.push(this.getValueString(header, datum));
      });
      exporTableCsv.addDatum(data);
    });
    exporTableCsv.download(this.getFileName());
  }

  /** Obtiene el valor de una celda en formato de texto. */
  private getValueString(header: ExportHeaderInterface<T>, datum: T): string {
    const value = this.getValueOriginal(header, datum);
    const valueStr = value !== undefined && value !== null ? String(value) : "";
    if (typeof header.display === "function") {
      return header.display(value, datum);
    }
    return valueStr;
  }

  /** Obtiene el valor original de la celda en formato string y si no existe "". */
  private getValueOriginal(header: ExportHeaderInterface<T>, datum: T): string {
    const result = header.key
      .split(".")
      .reduce(
        (acc, part) => (acc !== undefined && acc !== null ? acc[part] : null),
        datum
      );
    if (result === undefined) {
      try {
        return String(eval("datum." + header.key).access).toLowerCase();
      } catch (e) {
        return "";
      }
    }
    return result !== undefined && result !== null ? String(result) : "";
  }

  /** Aplica los estilos a una celda en el archivo Excel y PDF. */
  private callbackStylesExcelPdf(
    indexRow: number,
    indexCol: number,
    cell: CellExcel | CellPdf
  ): void {
    const styles = this.headers[indexCol].styles;
    if (styles) {
      const valueOriginal = this.getValueOriginal(
        this.headers[indexCol],
        this.data[indexRow]
      );
      styles(valueOriginal, new ExportCell(cell), this.data[indexRow]);
    }
  }

  /** Descarga el archivo en el formato seleccionado. */
  public download(type: ExportType): void {
    if (type === ExportType.EXCEL) {
      this.downloadExcel();
    } else if (type === ExportType.PDF) {
      this.downloadPdf();
    } else if (type === ExportType.CSV) {
      this.downloadCsv();
    }
  }
}
