/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import L from "leaflet";

import HeatMap from "./HeatMap/HeatMap.vue";
import MarkersMap from "./MarkersMap/MarkersMap.vue";

const SIZE_MAP_NOT_ALLOWED = 5000;

@Component({
  components: {
    HeatMap,
    MarkersMap,
  },
})
export default class TableMarkersMap extends Vue {
  @Prop({
    type: Object,
    default: () => ({
      getDataAreas: null,
      getDataZones: null,
      getDataSubzones: null,
      getData: null,
    }),
  })
  loaders!: any;
  @Prop({
    type: Object,
    default: () => ({ type: "", getName: null, isOnline: null }),
  })
  markerInfo!: any;
  @Prop({ type: Number, default: () => SIZE_MAP_NOT_ALLOWED })
  maxMarkersSizeAllowed;
  @Prop(Boolean) hasAnyActiveFilter!: boolean;
  //@Prop(Boolean) heatMap!: boolean;
  @Prop({ type: String, default: "" }) search!: string;

  $refs!: {
    markersMap: any;
  };

  fullScreen = false;
  currentZoom = 1.7;
  currentCenter = L.latLng(43.76404, 0);
  loadingData = false;

  get mapContainer() {
    return this.fullScreen ? "map-container-full-screen" : "map-container";
  }

  get isHeatMap() {
    return true;
  }

  /*set isHeatMap(value) {
    this.$emit("update:heatMap", value);
  }*/

  @Watch("isHeatMap")
  onChangeIsHeatMap() {
    this.$emit("disableFilters", true);
  }

  beforeDestroy() {
    this.$emit("disableFilters", false);
  }

  mounted() {
    this.$nextTick(() => {
      this.$emit("disableFilters", true);

      const map = this.isHeatMap ? "heatMap" : "markersMap";
      this.$refs[map].refreshTileLayer();
    });
  }

  updateMarkers() {
    if (this.$refs.markersMap && !this.isHeatMap)
      this.$refs.markersMap.getMarkers();
  }

  onSetLoading(res) {
    this.loadingData = res;
  }

  onHeatMapClosed() {
    this.$emit("close");
  }

  /*applySearch(newSearch) {
    this.$emit("applySearch", newSearch);
  }*/
}
