import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";

export default class ExportFirmwares<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.firmware"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Identificador.
    this.exportTable.addHeader({
      key: "id",
      label: t("firmware.fwid"),
    });
    // Familia
    this.exportTable.addHeader({
      key: "family",
      label: t("firmware.devicefamily"),
    });
    // Tipo.
    this.exportTable.addHeader({
      key: "typeAndSubtype",
      label: t("firmware.devicetype"),
    });
    // Versión.
    this.exportTable.addHeader({
      key: "versionSW",
      label: t("general.build"),
    });
    // Fecha de alta.
    this.exportTable.addHeader({
      key: "createdAt",
      label: t("firmware.uploaddate"),
      display: (value: string) => {
        return this.dateFormat(Number(value));
      },
    });
    // Historial de cambios.
    this.exportTable.addHeader({
      key: "changelog",
      label: t("firmware.changelog"),
    });
  }
}
