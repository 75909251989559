import { FirmwareEntity, FirmwareInterface } from "../entities/FirmwareEntity";
import { FirmwareService } from "./FirmwareService";

export class FirmwareController {
  /** Servicio de firmware. */
  protected service: FirmwareService;

  constructor() {
    this.service = new FirmwareService();
  }

  public async getFirmwareByDevice(
    family: string,
    type: string,
    subtype: string,
    versionHW: string[]
  ): Promise<FirmwareEntity[]> {
    return await this.service
      .getFirmwareByDevice(family, type, subtype, versionHW)
      .then((res: FirmwareInterface[]) => {
        return res.map((firmware) => new FirmwareEntity(firmware));
      });
  }
}
