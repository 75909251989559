<template lang="html" src="./Firmware.html"></template>

<!-- eslint-disable no-useless-escape -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { displayErrorMessage } from "@/api";

import { PANEL_FAMILY_CODE } from "@/constants";
import { EDIBOX_FAMILY_CODE } from "@/constants";

import firmwareService from "@/api/firmware";

import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";

import FilterTableHeaderSimple from "@/components/Table/FilterTableHeader/FilterTableHeaderSimple.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppSelect from "@/components/AppSelect.vue";

import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";

import dateMixin from "@/mixins/date.mixin";
import filterMixin from "@/mixins/filter.mixin";
import tableFrontMixin from "@/mixins/table.mixin/front";

import DialogOk from "@/ui/components/DialogOk/DialogOk.vue";

import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxHeaderFilter,
  DxScrolling,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxExport,
  DxToolbar,
  DxItem,
  DxOperationDescriptions,
  DxStateStoring,
  DxGrouping,
  DxMasterDetail,
  DxEditing,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import { DxCheckBox } from "devextreme-vue";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DxButton from "devextreme-vue/button";

import {
  FirmwareHWCompatibleRes,
  FirmwareUpload,
  FirmwareVersionV2,
  HWList,
} from "@/interfaces/firmwares";
import DxfExcelHelper from "@/helpers/DxfExcelHelper";
import { translateText } from "@/lang/i18n";
import DxfPdfHelper from "@/helpers/DxfPdfHelper";

import { FirmwareController } from "@/domain/controllers/FirmwareController";
import DialogUploadFw from "./DialogUploadFw/DialogUploadFw.vue";
import {
  FirmwareModel,
  FirmwareModelInterface,
} from "@/ui/models/FirmwareModel";
import { ExportType } from "@/domain/Export/ExportHeaderInterface";
import ExportFirmwares from "@/ui/Export/ExportFirmwares";

export const FILTER_DEFINITIONS = {
  idFilter: {
    literal: "firmware.fwid",
    format: "number",
  },
  familyFilter: {
    literal: "firmware.devicefamily",
  },
  typeFilter: {
    literal: "firmware.devicetype",
  },
  subtypeFilter: {
    literal: "firmware.devicesubtype",
  },
  fwVersionValueFilter: {
    literal: "general.version",
  },
  fwBuildFilter: {
    literal: "general.build",
  },
  dateValueStart: {
    literal: "firmware.uploadstartdate",
    format: "date",
  },
  dateValueEnd: {
    literal: "firmware.uploadenddate",
    format: "date",
  },
  criticalFilter: {
    literal: "firmware.critical",
  },
  automaticUpdateFilter: {
    literal: "firmware.automaticUpdate",
  },
  showAllFilter: {
    literal: "firmware.showAll",
  },
  latestFilter: {
    literal: "deviceDetails.fwLatest",
  },
};

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Firmware";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";
const dataGridRef = "dataGrid";

@Component({
  components: {
    AppListMenu,
    AppBreadcrumbs,
    AppKpiChipInfo,
    FilterTableHeaderSimple,
    AppFilterButtons,
    AppInputDate,
    AppAutocomplete,
    AppSelect,
    ExportTableDataButton,
    DxDataGrid,
    DxCheckBox,
    DxEditing,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    DxFilterRow,
    DxExport,
    DxToolbar,
    DxItem,
    DxOperationDescriptions,
    DxStateStoring,
    DxGrouping,
    DxMasterDetail,
    DxButton,
    DxSearchPanel,
    DialogUploadFw,
    DialogOk,
  },
  mixins: [dateMixin, tableFrontMixin, filterMixin],
})
export default class Firmware extends Vue {
  /** Indica que el firmware que se está creando es conectable o no. */
  connectableNew = false;
  /** Controlador del firmware. */
  ctrlFirmware = new FirmwareController();

  $moment!: any;
  filterStartWith!: Function;
  filterDate!: Function;
  saveFilters!: Function;
  checkHasAnyActiveFilter!: Function;
  filterOptions!: any;
  filterOptions_!: any;
  loadingData!: boolean;

  firmwaresFiltered!: any;

  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  tableProps: any = {
    sortBy: ["id"],
  };
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  PANEL_FAMILY_CODE = PANEL_FAMILY_CODE;
  EDIBOX_FAMILY_CODE = EDIBOX_FAMILY_CODE;
  selectionOptions: any = {};
  isLoadingTypes: number | boolean = false;
  // Dialog with steps to upload a firmware
  fwDialog = false;
  dialogSteps = 1;
  // Upload the file (Step 1)
  fileName: string | null = null;
  existsFile = false;
  fwSHA = "";
  existsSha = false;
  formDataFile: FormData | null = null;
  showProgress = false;
  fileProgress = 0;
  indeterminate = false;
  uploadResponse: any = null;
  validFormStep1 = true;
  // Firmware details (Step 2)
  deviceFamily: string | null = null;
  deviceType: string | null = null;
  deviceSubtype: string | null = null;
  fwVersion: string | null = null;
  versionHW: string | null = null;
  versionSW: string | null = null;
  fwBuild = "";
  changelog = "";
  details = "";
  critical = false;
  automaticUpdate = false;
  validFormStep2 = true;
  validFormStep3 = true;
  // Firmware edit
  editDialog = false;
  currentFirmware: any = null;
  changelogEdit = "";
  detailsEdit = "";
  criticalEdit = false;
  automaticUpdateEdit = false;
  validEditForm = true;
  confirmRemoveFirmwareId = null;

  $refs!: {
    editForm: HTMLFormElement;
    formStep1: HTMLFormElement;
    formStep2: HTMLFormElement;
    formStep3: HTMLFormElement;
    fwFile: any;
  };

  selectAll = false;

  hwList: HWList[] = [];
  hwListDetailEdit: HWList[] = [];
  automaticVisible = true;
  pageSizes = [5, 10, "all"];
  checkBoxValue = true;
  isCheckedAutomatic = false;
  isCheckedLatest = false;
  //showConfirmCheckbox = false;

  /** Opciones del desplegable de la fila */
  get menuOptions() {
    return [
      {
        icon: "edit",
        text: this.$t("general.edit"),
        action: (item) => this.prepareToEdit(item),
        allowed: this.$ability.can("edit", "firmwares"),
      },
      {
        icon: "cloud_download",
        text: this.$t("general.download"),
        action: (item) => this.downloadFile(item.id),
        allowed: this.$ability.can("download", "firmwares"),
      },
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: (item) => this.setConfirmRemoveFirmwareId(item.id),
        allowed: this.$ability.can("delete", "firmwares"),
      },
    ];
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };
    if (filters.criticalFilter === false) delete filters.criticalFilter;
    if (filters.showAllFilter === false) delete filters.showAllFilter;
    if (filters.automaticUpdateFilter === false)
      delete filters.automaticUpdateFilter;

    return this.checkHasAnyActiveFilter(filters);
  }

  data() {
    return {
      dataGridRef,
      detalleColumns: [
        { dataField: "HW", caption: "HW" },
        { dataField: "AUTOMATIC", caption: "AUTOMATIC" },
        { dataField: "Latest", caption: "Latest" },
      ],
    };
  }

  get maestroColumns() {
    return [
      { dataField: "id", caption: this.$t("firmware.fwid") },
      { dataField: "family", caption: this.$t("firmware.devicefamily") },
      { dataField: "typeAndSubtype", caption: this.$t("firmware.devicetype") },
      { dataField: "version", caption: this.$t("general.version") },
      { dataField: "build", caption: this.$t("general.build") },
      {
        dataField: "createdAt",
        caption: this.$t("firmware.uploaddate"),
        dataType: "datetime",
        format: "dd-MM-yyyy",
      },
      { dataField: "changelog", caption: this.$t("firmware.changelog") },
    ];
  }

  /** Devuelve el listado del detalle de la versión del hardware. */
  getDetalleHw(firmware) {
    const detalleHw = this.firmwaresInfo.lista.find(
      (objeto) => objeto.id === firmware.data.id
    );
    return detalleHw.hwList;
  }

  get firmwaresInfo() {
    const listInfo = this.$store.getters["firmwares/firmwaresInfo"];
    if (this.filterOptions_ && listInfo.list)
      listInfo.list = listInfo.list.filter((i) =>
        this.filterFirmwares(i, this.filterOptions_)
      );

    return listInfo;
  }

  get numberOfDeviceTypes() {
    return this.$store.getters["firmwares/numberOfDeviceTypes"];
  }

  get showConfirmRemoveFirmware() {
    return !!this.confirmRemoveFirmwareId;
  }

  set showConfirmRemoveFirmware(localValue) {
    if (!localValue) this.confirmRemoveFirmwareId = null;
  }

  get listFamilies() {
    return this.$store.getters["firmwares/listFamilies"];
  }

  get listFamiliesObject() {
    let families = this.listFamilies;
    families.forEach(
      (item) => (item.text = this.$t("device.deviceDetails." + item.name))
    );
    return families;
  }

  get listTypes() {
    return this.$store.getters["firmwares/listTypes"];
  }

  get listSubtypes() {
    return this.$store.getters["firmwares/listSubtypes"];
  }

  get files() {
    const filesNew: any = [];
    this.firmwaresFiltered.forEach((firmware) => {
      filesNew[firmware.id] = firmware.changelog;
    });
    return filesNew;
  }

  get fileRules() {
    const patterns = [
      /* /^PANEL_(\d{2})\.(\d{2})\.(\d{3})\.([\w\.]+)$/,
      /^PANEL_CORA_(\d{2})\.(\d{2})\.(\d{3})\.([\w\.]+)$/,
      /^EDIBOX_(\d{2})\.(\d{2})\.(\d{3})\.([\w\.]+)$/,
      /^EDIBOX_BOXM2_(\d{2})\.(\d{2})\.(\d{3})\.([\w\.]+)$/,
       */
      /^([A-Z\d]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
      /^([A-Z\d]*)_([A-Z\d]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
      /^([A-Z\d]*)_([A-Z\d\-]*)_([A-Z\d\-]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
    ];

    const fileformatErrorMessage = this.$t("firmware.fileformat");

    return [
      (v) => {
        for (const pattern of patterns) {
          if (pattern.test(v)) {
            return true;
          }
        }

        //if (v && !v.startsWith("PANEL") && !v.startsWith("EDIBOX")) {
        if (v) {
          return fileformatErrorMessage;
        }

        return true; // Valor por defecto si no se cumple ninguna regla
      },
    ];
  }

  get fwBuildRules() {
    return [(v) => !v || v.length <= 64 || this.$t("firmware.fwbuildmax")];
  }

  get changelogRules() {
    return [(v) => !v || v.length <= 50 || this.$t("firmware.changelogmax")];
  }

  get detailsRules() {
    return [(v) => !v || v.length <= 255 || this.$t("firmware.detailsmax")];
  }

  get versionRules() {
    return [
      (v) => !!v || this.$t("firmware.versionrequired"),
      (v) => /^\d\d\.\d\d$/.test(v) || this.$t("firmware.versionformat"),
    ];
  }

  get shaRules() {
    return [
      (v) => !!v || this.$t("firmware.sharequired"),
      (v) => (v && v.length == 64) || this.$t("firmware.shaformat"),
    ];
  }

  get notEmptyRule() {
    return [(v) => !!v || this.$t("firmware.fieldrequired")];
  }

  /**
   * Comprueba si se ha seleccionado algún HW.
   * En el formulario de edición.
   */
  get isSelectedAnyHWDialogEdit(): boolean {
    return this.hwListDetailEdit.some((i: HWList) => {
      return i.selectHW === true;
    });
  }

  /**
   * Comprueba si se ha seleccionado un HW.
   * En el formulario de edición.
   */
  get isSelectedOneHWDialogEdit(): boolean {
    return (
      this.hwListDetailEdit.filter((i: HWList) => {
        return i.selectHW === true;
      }).length === 1
    );
  }

  /**
   * Comprueba si se ha seleccionado algún HW.
   * En el formulario de subida.
   */
  get isSelectedAnyHWDialogUpload(): boolean {
    return this.hwList.some((i: HWList) => {
      return i.selectHW === true;
    });
  }

  /**
   * Comprueba si se ha seleccionado un HW.
   * En el formulario de subida.
   */
  get isSelectedOneHWDialogUpload(): boolean {
    return (
      this.hwList.filter((i: HWList) => {
        return i.selectHW === true;
      }).length === 1
    );
  }

  mounted() {
    this.getFamilies();

    if (
      this.filterOptions.familyFilter &&
      this.filterOptions.familyFilter !== PANEL_FAMILY_CODE &&
      this.filterOptions.familyFilter !== EDIBOX_FAMILY_CODE
    )
      this.getDeviceType(this.filterOptions.familyFilter, false);
  }

  setConfirmRemoveFirmwareId(v) {
    this.confirmRemoveFirmwareId = v;
  }

  getDeviceTypesCount() {
    this.$store.dispatch("firmwares/getNumberOfDeviceTypes");
  }

  refreshList() {
    this.loadingData = true;

    this.getDeviceTypesCount();
    this.$store
      .dispatch("firmwares/getFirmwaresList")
      .then(() => (this.loadingData = false));
  }

  onFilterSubmit(e) {
    e.preventDefault();

    if (this.filterOptions.criticalFilter === false)
      delete this.filterOptions.criticalFilter;
    if (this.filterOptions.automaticUpdateFilter === false)
      delete this.filterOptions.automaticUpdateFilter;
    if (this.filterOptions.showAllFilter === false)
      delete this.filterOptions.showAllFilter;

    this.saveFilters();

    this.tableProps.page = 1;

    this.refreshList();
  }

  getFamilies() {
    this.isLoadingTypes = 1;

    this.$store
      .dispatch("firmwares/getDeviceFamily")
      .then(() => (this.isLoadingTypes = false))
      .catch(() => (this.isLoadingTypes = false));
  }

  getDeviceType(familyValue, reset = true) {
    if (reset) {
      delete this.filterOptions.typeFilter;
      delete this.filterOptions.subtypeFilter;
    }

    if (
      familyValue &&
      familyValue !== PANEL_FAMILY_CODE &&
      familyValue !== EDIBOX_FAMILY_CODE
    ) {
      this.isLoadingTypes = 2;

      firmwareService
        .getDeviceTypes(familyValue)
        .then((res) => {
          this.selectionOptions.types = res.data;
          this.selectionOptions.types.forEach(
            (item) => (item.text = this.$t("device.deviceDetails." + item.name))
          );

          this.isLoadingTypes = false;

          if (this.filterOptions.typeFilter)
            this.getDeviceSubtype(this.filterOptions.typeFilter, false);
        })
        .catch((err) => {
          displayErrorMessage(err, {
            general: ["general.error.loadingDataForm"],
          });
          this.isLoadingTypes = false;
        });
    }
  }

  getDeviceSubtype(typeValue, reset = true) {
    if (reset) delete this.filterOptions.subtypeFilter;

    if (typeValue) {
      this.isLoadingTypes = 3;

      firmwareService
        .getDeviceSubTypes(this.filterOptions.familyFilter, typeValue)
        .then((res) => {
          this.selectionOptions.subtypes = res.data;
          this.selectionOptions.subtypes.forEach(
            (item) => (item.text = this.$t("device.deviceDetails." + item.name))
          );

          this.isLoadingTypes = false;
        })
        .catch((err) => {
          displayErrorMessage(err, {
            general: ["general.error.loadingDataForm"],
          });

          this.isLoadingTypes = false;
        });
    }
  }

  filterFirmwares(item, filters) {
    if (filters.familyFilter) {
      const show = item.family === filters.familyFilter;

      if (!show) return false;
    }

    if (filters.typeFilter) {
      const show = item.type === filters.typeFilter;

      if (!show) return false;
    }

    if (filters.subtypeFilter) {
      const show = item.subtype === filters.subtypeFilter;

      if (!show) return false;
    }

    if (filters.fwVersionValueFilter) {
      const show = this.filterStartWith(
        item.version,
        filters.fwVersionValueFilter
      );

      if (!show) return false;
    }

    if (filters.fwBuildFilter) {
      const show = item.build === filters.fwBuildFilter;

      if (!show) return false;
    }

    const creationStartDate = filters.dateValueStart
      ? this.$moment(filters.dateValueStart).startOf("day")
      : null;
    const creationEndDate = filters.dateValueEnd
      ? this.$moment(filters.dateValueEnd).endOf("day")
      : null;

    if (creationStartDate || creationEndDate) {
      const show = this.filterDate(
        item.createdAt,
        creationStartDate,
        creationEndDate
      );

      if (!show) return false;
    }

    if (filters.idFilter) {
      const show = this.filterStartWith(item.id, filters.idFilter);

      if (!show) return false;
    }

    if (filters.criticalFilter) {
      const show = item.critical === true;

      if (!show) return false;
    }

    if (filters.automaticUpdateFilter) {
      const show = item.automaticUpdate === true;

      if (!show) return false;
    }

    if (!filters.showAllFilter) {
      const show = item.latest === true;

      if (!show) return false;
    }

    return true;
  }

  openDialog() {
    this.$store.dispatch("firmwares/getDeviceFamily");
    if (this.$refs.formStep1) this.$refs.formStep1.reset();
    this.fwDialog = true;
  }

  updateDeviceType() {
    this.$store.dispatch("firmwares/getDeviceType", {
      family: this.deviceFamily,
    });
  }

  updateDeviceSubtype() {
    this.$store.dispatch("firmwares/getDeviceSubtype", {
      family: this.deviceFamily,
      type: this.deviceType,
    });
  }

  // Functions to check the file and his sha
  pickFile() {
    this.$refs.fwFile.value = null;
    this.$refs.fwFile.click();
  }

  checkFileExists(event) {
    this.fileName = this.$refs.fwFile.files[0].name;
    this.existsFile = event.currentTarget.type === "file";
    let formData = new FormData();
    formData.append("file", this.$refs.fwFile.files[0]);
    this.formDataFile = formData;
  }

  checkShaExists() {
    let fwSHAlowerCase = this.fwSHA.toLowerCase();
    this.existsSha = this.fwSHA !== null;
    this.fwSHA = fwSHAlowerCase;
  }

  checkFw() {
    if (this.existsFile && this.existsSha) {
      this.uploadFile();
    }
  }

  checkFw2() {
    const firmwareDetailsInfo = {
      family: this.deviceFamily,
      type: this.deviceType,
      subtype: this.deviceSubtype,
    };
    this.hwList = [];

    this.automaticVisible =
      this.deviceFamily === "MONITOR" || this.deviceFamily === "PANEL"
        ? true
        : false;

    firmwareService
      .getFirmwareshardwareCompatible(firmwareDetailsInfo)
      .then((result) => {
        this.isEmptyResponse(result)
          ? console.log("Respuesta sin datos")
          : this.processResponseData(result.data);
      })
      .catch(this.handleError);

    this.dialogSteps = 3;
  }

  isEmptyResponse(response) {
    return !response.data;
  }

  processResponseData(data) {
    data.hwList.forEach((item) => {
      let hw = {
        selectHW: false,
        hw: item,
        automaticUpdate: false,
        latest: false,
      };
      this.hwList.push(hw);
    });
  }

  handleError(error) {
    console.error("Error al obtener los datos:", error);
  }

  showConfirmCheckboxAutUpd = false;
  showConfirmCheckboxLatest = false;
  handleClickCheckBox(automatic: boolean, hw: string, checkValue: boolean) {
    if (checkValue) {
      const family = this.deviceFamily || this.currentFirmware.family;
      const type = this.deviceType || this.currentFirmware?.type;
      const subtype = this.deviceSubtype || this.currentFirmware?.subtype;

      if (automatic) {
        this.ctrlFirmware.existAutomaticUpdate(family, type, subtype, hw).then(
          (res: boolean) => {
            this.showConfirmCheckboxAutUpd = res;
          },
          (err) => {
            console.error(err);
          }
        );
      } else {
        this.ctrlFirmware.existLatest(family, type, subtype, hw).then(
          (res: boolean) => {
            this.showConfirmCheckboxLatest = res;
          },
          (err) => {
            console.error(err);
          }
        );
      }
    }
  }

  onUploadFileProgress(progressEvent) {
    this.showProgress = true;
    this.fileProgress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (this.fileProgress === 100) {
      this.indeterminate = true;
    }
  }

  uploadFile() {
    firmwareService
      .uploadFirmware(this.fwSHA, this.formDataFile, this.onUploadFileProgress)
      .then(
        (res) => {
          this.uploadResponse = res.data.dataFirmwareDTO.firmwareDto;
          this.dialogSteps = 2;
          this.resetProgress();
          // eslint-disable-next-line
          const nameSplit = this.fileName!.split(/[_.]+/);
          this.deviceFamily = nameSplit[0];

          if (nameSplit.length === 7) {
            this.updateDeviceType();
            this.deviceType = nameSplit[1];
          } else if (nameSplit.length === 8) {
            this.updateDeviceType();
            this.deviceType = nameSplit[1];

            this.updateDeviceSubtype();
            this.deviceSubtype = nameSplit[2];
          }

          this.versionHW = nameSplit[nameSplit.length - 5];
          this.versionSW = nameSplit[nameSplit.length - 4];
          this.fwBuild = nameSplit[nameSplit.length - 3];

          this.fwVersion =
            this.versionHW + "." + this.versionSW + "." + this.fwBuild;

          this.ctrlFirmware
            .getFwSubType(
              this.deviceFamily,
              this.deviceType ?? "",
              this.deviceSubtype ?? ""
            )
            .then((res) => {
              this.connectableNew = res.connectable;
            });
        },
        (err) => {
          this.resetProgress();

          this.$store.dispatch("errorHandler", {
            error: err,
            badRequestText: ["firmware.error.wrongName"],
            notAcceptableText: ["firmware.error.wrongSha"],
            conflictText: ["firmware.error.fwRegistered"],
            genericErrorText: ["firmware.error.notUploaded"],
          });
        }
      );
  }

  /** Obtiene el firmware de la ventana de "subir firmware". */
  getFirmwareDetailsInfoForDialogUpload(): FirmwareUpload {
    const firmwareDetailsInfo: FirmwareUpload = {
      family: this.deviceFamily,
      type: this.deviceType,
      subtype: this.deviceSubtype,
      changelog: this.changelog,
      details: this.details,
      critical: this.critical,
      automaticUpdate: this.automaticUpdate,
      hwList: [],
      firmwareVersionV2: {
        fwMajor: this.versionHW ?? "00",
        fwMinor: this.versionSW ?? "00",
        fwBuild: this.fwBuild,
      },
    };
    firmwareDetailsInfo.hwList = this.hwList
      .filter((element: HWList) => element.selectHW)
      .map((element: HWList) => ({
        hw: element.hw,
        automaticUpdate: element.automaticUpdate,
        latest: element.latest,
        selectHW: true,
      }));

    return firmwareDetailsInfo;
  }

  /**
   * Guarda la subida de un nuevo firmware.
   * Si el firmware es compatible, es necesario
   * que haya seleccionado, al menos, un hardware.
   * Si el firmware no es compatible, es necesario
   * que seleccione, solo, un hardware.
   */
  uploadFirmwareDetails() {
    const firmwareDetailsInfo = this.getFirmwareDetailsInfoForDialogUpload();

    const isCompFirm = this.isCompatibleFirmware(
      firmwareDetailsInfo.firmwareVersionV2
    );
    if (
      (isCompFirm && this.isSelectedAnyHWDialogUpload) ||
      (!isCompFirm && this.isSelectedOneHWDialogUpload)
    ) {
      firmwareService
        .uploadFirmwareDetails(this.uploadResponse.id, firmwareDetailsInfo)
        .then(
          () => {
            this.fwDialog = false;
            this.dialogSteps = 1;
            this.resetFormValues();

            this.$store.dispatch("firmwares/getFirmwaresList");

            this.uploadResponse = null;
          },
          (err) => {
            this.$store.dispatch("errorHandler", {
              error: err,
              forbiddenText: ["firmware.error.completedStatus"],
              notFoundText: ["firmware.error.notFound"],
              genericErrorText: ["firmware.error.registrationFail"],
            });
          }
        );
    } else {
      this.$store.dispatch("errorHandlerCustom", {
        text: isCompFirm ? "firmware.needAnyHW" : "firmware.needOneHW",
      });
    }
  }

  toggleSelectAll(detalleData) {
    detalleData.forEach((item) => {
      item.selectHW = this.selectAll;
    });
  }

  toggleAutomatic(rowData) {
    rowData.AUTOMATIC = !rowData.AUTOMATIC;
  }

  cancelUpload() {
    if (this.dialogSteps === 2 || this.dialogSteps === 3) {
      const idToDelete = this.uploadResponse.id;
      this.deleteFileFromSystem(idToDelete);
      this.resetFormValues();
    }

    this.fwDialog = false;
    this.dialogSteps = 1;
    this.resetFormValues();
  }

  deleteFileFromSystem(idToDelete) {
    firmwareService.deleteFirmware(idToDelete).then(null, (err) => {
      displayErrorMessage(err, { general: ["firmware.error.deleteFile"] });
    });
  }

  resetFormValues() {
    this.$refs.formStep1.reset();
    this.$refs.formStep2.reset();
    this.$refs.formStep3.reset();
  }

  downloadFile(id) {
    this.$refs["download" + id].click();
  }

  resetProgress() {
    this.fileProgress = 0;
    this.indeterminate = false;
    this.showProgress = false;
  }

  /** Acción que se ejecuta cuando le das al botón de editar de la fila. */
  async prepareToEdit(firmware) {
    this.editDialog = true;
    this.currentFirmware = firmware;
    this.changelogEdit = this.currentFirmware.changelog;
    this.detailsEdit = this.currentFirmware.details;

    const hwlisttemp1 = this.currentFirmware.hwList.map(
      ({ hw, automaticUpdate, latest }) => ({
        selectHW: true,
        hw,
        automaticUpdate,
        latest,
      })
    );

    /*const firmwareDetailsInfo = {
      family: this.currentFirmware.family,
      type: this.currentFirmware.type,
      subtype: this.currentFirmware.subtype,
    };*/

    /*const {
      data: { hwList },
    } = await firmwareService.getFirmwareshardwareCompatible(
      firmwareDetailsInfo
    );*/

    const fwHWComp: FirmwareHWCompatibleRes =
      await this.ctrlFirmware.getFwHwCompatible(
        this.currentFirmware.family,
        this.currentFirmware.type,
        this.currentFirmware.subtype
      );

    const hwlisttemp2 = fwHWComp.hwList.map((item) => ({
      selectHW: false,
      hw: item,
      automaticUpdate: false,
      latest: false,
    }));

    this.hwListDetailEdit = this.unifyHwLists(hwlisttemp1, hwlisttemp2);
  }

  unifyHwLists(list1: HWList[], list2: HWList[]): HWList[] {
    const uniqueMap = new Map<string, HWList>();

    [...list1, ...list2].forEach((item: HWList) => {
      if (!uniqueMap.has(item.hw)) {
        uniqueMap.set(item.hw, item);
      }
    });

    return [...uniqueMap.values()];
  }

  onselectHWChange(data) {
    if (!data.selectHW) {
      data.data.automaticUpdate = false;
      data.data.latest = false;
    }
  }

  /**
   * Comprueba si el firmware es de los que tienen el nuevo formato.
   * @param firmware Versión del firmware.
   */
  isCompatibleFirmware(firmware: FirmwareVersionV2): boolean {
    return parseInt(firmware.fwMajor) !== 0;
  }

  /**
   * Guarda la edición de un firmware.
   * Si el firmware es compatible, es necesario
   * que haya seleccionado, al menos, un hardware.
   * Si el firmware no es compatible, es necesario
   * que seleccione, solo, un hardware.
   */
  async editFirmwareProperties() {
    const isCompFirm = this.isCompatibleFirmware(
      this.currentFirmware.firmwareVersionV2
    );
    if (
      (isCompFirm && this.isSelectedAnyHWDialogEdit) ||
      (!isCompFirm && this.isSelectedOneHWDialogEdit)
    ) {
      this.editDialog = false;

      try {
        await firmwareService.setStatus(this.currentFirmware.id, "INPROGRESS");

        this.currentFirmware.changelog = this.changelogEdit;
        this.currentFirmware.details = this.detailsEdit;
        this.currentFirmware.hwList = this.hwListDetailEdit
          .filter((element: HWList) => element.selectHW)
          .map(({ hw, automaticUpdate, latest }) => ({
            hw,
            automaticUpdate,
            latest,
          }));

        this.currentFirmware.status = "INPROGRESS";
        await firmwareService.uploadFirmwareDetails(
          this.currentFirmware.id,
          this.currentFirmware
        );
        await this.$store.dispatch("firmwares/getFirmwaresList");
      } catch (err) {
        await firmwareService.setStatus(this.currentFirmware.id, "COMPLETED");
        this.$store.dispatch("errorHandler", {
          error: err,
          forbiddenText: ["firmware.error.completedStatus"],
          notFoundText: ["firmware.error.notFound"],
          genericErrorText: ["firmware.error.registrationFail"],
        });
      }
    } else {
      this.$store.dispatch("errorHandlerCustom", {
        text: isCompFirm ? "firmware.needAnyHW" : "firmware.needOneHW",
      });
    }
  }

  cancelEdit() {
    this.editDialog = false;
    this.$refs.editForm.resetValidation();
    this.currentFirmware = null;
    this.refreshList();
  }

  removeFirmware() {
    const id = this.confirmRemoveFirmwareId;

    this.showConfirmRemoveFirmware = false;

    firmwareService
      .removeFirmware(id)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("firmware.info.deletedJob"),
        });
        this.refreshList();
      })
      .catch((err) => {
        this.$store.dispatch("errorHandler", {
          error: err,
          conflictText: ["firmware.error.haveJobs"],
          genericErrorText: ["firmware.error.deleteFail"],
        });
      });
  }

  async onExportingExcel(e) {
    e.cancel = true;
    try {
      const excel = new DxfExcelHelper(translateText("menu.firmware"));
      excel.export(e.component);
    } catch (error) {
      console.log(error);
    }
  }

  async exportToPdf() {
    try {
      if (this.$refs[dataGridRef].instance) {
        const pdf = new DxfPdfHelper(translateText("menu.firmware"));
        pdf.export(this.$refs[dataGridRef].instance);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async automaticUpdateFilterChange(event) {
    if (event) {
      const firmwaresAutomaticUpdate = this.firmwaresInfo.lista.filter(
        (objeto) => objeto.hwList.some((element) => element?.automaticUpdate)
      );

      this.$store.commit("firmwares/setFirmwaresInfo", {
        lista: firmwaresAutomaticUpdate,
        totalFirmwares: this.firmwaresInfo.totalFirmwares,
      });
    } else {
      await this.$store.dispatch("firmwares/getFirmwaresList");
      if (this.filterOptions.latestFilter) {
        this.latestFilterChange(true);
      }
    }
  }

  async latestFilterChange(event) {
    if (event) {
      const firmwaresLatest = this.firmwaresInfo.lista.filter((objeto) =>
        objeto.hwList.some((element) => element?.latest)
      );

      this.$store.commit("firmwares/setFirmwaresInfo", {
        lista: firmwaresLatest,
        totalFirmwares: this.firmwaresInfo.totalFirmwares,
      });
    } else {
      await this.$store.dispatch("firmwares/getFirmwaresList");
      if (this.filterOptions.automaticUpdateFilter) {
        this.automaticUpdateFilterChange(true);
      }
    }
  }

  /** Desabilita la casilla de actualización automática. */
  isDisabledAutUpdate(rowData: HWList, curFw: FirmwareModelInterface) {
    const firmwareEntity = new FirmwareModel(curFw);
    return !(rowData.selectHW && firmwareEntity.canAutoUpdate());
  }

  /** Cuando se inserta un registro nuevo se recarga la tabla. */
  onInsertFirmware() {
    this.refreshList();
  }

  public onExport(type: ExportType) {
    const data =
      this.$refs[dataGridRef].instance?.getDataSource()?.store() ?? [];
    const filteredData = this.firmwaresInfo.lista;
    debugger;
    const exportTable = new ExportFirmwares();
    exportTable.addData(filteredData);
    exportTable.download(type);
  }
}
</script>
<style>
.dx-checkbox-icon {
  border-color: #757575;
  background-color: #fff;
  border-width: 2px;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #757575;
  background-color: #fff;
  border-width: 2px;
}

.dx-widget .dx-checkbox .dx-datagrid-checkbox-size {
  border-color: #757575;
  background-color: #fff;
  border-width: 2px;
}

.dx-datagrid-search-panel {
  margin: 0 0 0 15px;
  width: 328px !important;
}
</style>
