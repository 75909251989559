import DeviceEntity from "@/domain/entities/DeviceEntity";
import { InsFilInsFwSelInterface } from "./InstallationFwsSelectorInterface";
import { FirmwareEntity } from "@/domain/entities/FirmwareEntity";
import DeviceCacheReaderEntity from "@/domain/deviceCacheReader/DeviceCacheReaderEntity";

export class InsFilInsFwSelEntity implements InsFilInsFwSelInterface {
  devices: Array<DeviceInsFwSelEntity>;
  family: string;
  subtype: string;
  type: string;
  optionsFirmware: Array<FirmwareEntity>;
  private _downgrade: boolean;
  private _selectedFirmware?: FirmwareEntity;
  changeFirmware?: (firmware: FirmwareEntity | undefined) => void;
  constructor(params: InsFilInsFwSelInterface) {
    this.devices = params.devices;
    this.family = params.family;
    this.subtype = params.subtype;
    this.type = params.type;
    this.optionsFirmware = params.optionsFirmware;
    this._downgrade = params.downgrade;
  }

  get selectedFirmware(): FirmwareEntity | undefined {
    return this._selectedFirmware;
  }

  set selectedFirmware(firmware: FirmwareEntity | undefined) {
    this._selectedFirmware = firmware;
    this.updateStatusDisabledDevices();
    this.changeFirmware?.(this._selectedFirmware);
  }

  get downgrade(): boolean {
    return this._downgrade;
  }

  set downgrade(downgrade: boolean) {
    this._downgrade = downgrade;
    this.updateStatusDisabledDevices();
  }
  /**
   * Cuando se selecciona un firmware, se actualiza el estado de los dispositivos.
   * Si el firmware no incluye el "versionHW" del dispositivo, se deshabilita.
   * Si el firmware incluye el "versionHW" del dispositivo, se habilita.
   * Si no hay firmware seleccionado, se deshabilita.
   */
  private updateStatusDisabledDevices() {
    for (const device of this.devices) {
      device.disabled = !this.selectedFirmware?.hwIncludes(device.versionHW);
      if (!device.disabled && !this.downgrade) {
        device.disabled = device.fwVersionV2.isHigherOrEqualThan(
          this.selectedFirmware?.firmwareVersionV2
        );
      }
    }
  }

  /**
   * Devuelve si hay "devices" con "selected" en "true".
   * @returns {boolean} Si hay "devices" seleccionados.
   */
  public hasSelectedDevices(): boolean {
    return this.devices.some((d) => d.selected);
  }

  /**
   * Devuelve el número de "devices" seleccionados.
   * @returns {number} Número de "devices" seleccionados.
   */
  public numSelectedDevices(): number {
    return this.devices.filter((d) => d.selected).length;
  }

  /**
   * Devuelve si hay "devices" con "jobOn" en "true".
   * @returns {boolean} Si hay "devices" con "jobOn" en "true".
   */
  public hasDevicesWithJobOn(): boolean {
    return this.devices.some((d) => d.jobOn);
  }

  /**
   * Devuelve el número de "devices" con "jobOn" en "true".
   * @returns {number} Número de "devices" con "jobOn" en "true".
   */
  public numDevicesWithJobOn(): number {
    return this.devices.filter((d) => d.jobOn).length;
  }

  /**
   * Devuelve si hay "devices" con "jobOn" en "false" y
   * recorre los "devices" para comprobar que existe alguno
   * que tenga la "versionHW" dentro del "hwList.versionHW" del "selectedFirmware".
   * @returns {boolean} Si hay dispositivos seleccionables.
   */
  get hasDevicesWithNoJobOnAndHWList(): boolean {
    if (!this.selectedFirmware) return false;
    return this.devices.some(
      (d) => !d.jobOn && this.selectedFirmware?.hwIncludes(d.versionHW)
    );
  }

  /**
   * Devuelve si están todos los dispositivos habilitados están seleccionados.
   * @returns {boolean} Si están todos los dispositivos seleccionados.
   */
  public areAllDevicesSelected(): boolean {
    return this.devices.every((device) => device.disabled || device.selected);
  }

  /**
   * Método que si están todos los "devices" seleccionados, los deselecciona.
   * Y si no están todos seleccionados, los selecciona todos.
   */
  public selectUnselectAllDevices() {
    const areAllSelected = this.areAllDevicesSelected();
    if (areAllSelected) {
      this.unselectAllDevices();
    } else {
      if (this._selectedFirmware) {
        for (const device of this.devices) {
          if (this._selectedFirmware.hwIncludes(device.versionHW)) {
            device.selected = true;
          }
        }
      } else {
        this.devices.map((d) => (d.selected = true));
      }
    }
  }

  /**
   * Método que si hay "devices" con "selected" en "true", los deselecciona.
   */
  public unselectAllDevices() {
    this.devices.map((d) => (d.selected = false));
  }

  public getNameIcon(): string {
    const dcr = new DeviceCacheReaderEntity({
      id: "",
      family: this.family,
      type: this.type,
      subtype: this.subtype,
    });
    return dcr.getNameIcon();
  }
}

export class DeviceInsFwSelEntity extends DeviceEntity {
  private _selected = false;
  private _jobOn = false;
  private _disabled = true;
  changeSelected?: () => void;

  get selected(): boolean {
    return this._selected;
  }

  /** Solo se puede seleccionar si no está deshabilitado. */
  set selected(selected: boolean) {
    if (selected && this._disabled) {
      return;
    }
    this._selected = selected;
    this.changeSelected?.();
  }

  get jobOn(): boolean {
    return this._jobOn;
  }

  /** Si hay un trabajo en curso, no se puede seleccionar y se deshabilita. */
  set jobOn(jobOn: boolean) {
    this._jobOn = jobOn;
    if (this._jobOn) {
      this.selected = false;
      this.disabled = true;
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /** Si hay un trabajo en curso, no se puede habilitar. */
  set disabled(disabled: boolean) {
    if (disabled) {
      this._disabled = disabled;
    } else {
      if (this._jobOn) return;
      this._disabled = disabled;
    }
  }
}
