import { FirmwareInterface } from "../entities/FirmwareEntity";
import { AxiosHelper } from "../helpers/AxiosHelper";

export class FirmwareService {
  protected axiosService: AxiosHelper;
  private urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/firmware/api/v2";
  }

  public async getFirmwareByDevice(
    family: string,
    type: string,
    subtype: string,
    versionHW: string[]
  ): Promise<FirmwareInterface[]> {
    let url = this.urlBase + "/firmware";
    url += "?family=" + family;
    if (family !== "PANEL") {
      url += "&type=" + type + "&subtype=" + subtype;
    } else if (type === "CORA") {
      url += "&type=" + type;
    }
    url += "&versionHW=" + versionHW.join(",");
    return this.axiosService.get(url);
  }
}
