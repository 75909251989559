import { Vue, Component, Prop } from "vue-property-decorator";
import MyButton from "primevue/button";

@Component({
  components: {
    MyButton,
  },
})
export default class FrmxButton extends Vue {
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: false }) public loading!: boolean;

  onClick() {
    this.$emit("click");
  }
}
