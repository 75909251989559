/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosHelper } from "../helpers/AxiosHelper";

export default class InstallationService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;
  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/installation/api/v1";
  }

  public async getTopology(installationId: string): Promise<any> {
    const url = this.urlBase + "/topology/" + installationId;
    return this.axiosService.get(url);
  }

  public async getInstallation(id: string): Promise<any> {
    const url = this.urlBase + "/installation/" + id;
    return this.axiosService.get(url);
  }
}
