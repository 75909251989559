/* eslint-disable @typescript-eslint/no-explicit-any */
import InstallationService from "./InstallationService";

export default class InstallationController {
  protected installationService: InstallationService;
  constructor() {
    this.installationService = new InstallationService();
  }

  public async getTopology(installationId: string): Promise<any> {
    const data = await this.installationService.getTopology(installationId);
    return data;
  }

  public async getInstallation(id: string): Promise<any> {
    const data = await this.installationService.getInstallation(id);
    return data;
  }
}
