import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";
import LanguagesHelper from "@/helpers/languagesHelper";
import * as i18nIsoCountries from "i18n-iso-countries";
import i18n from "@/lang/i18n";
import ExportCell from "@/domain/Export/ExportCell";
import {
  ExportType,
  HorizontalAlign,
} from "@/domain/Export/ExportHeaderInterface";

export default class ExportUsers<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.users"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Correo electrónico.
    this.exportTable.addHeader({
      key: "email",
      label: t("user.email"),
    });
    // Idioma.
    this.exportTable.addHeader({
      key: "locale",
      label: t("menu.language"),
      display: (value: string) => {
        return LanguagesHelper.getLanguageName(value.toLowerCase()) as string;
      },
    });
    // País.
    this.exportTable.addHeader({
      key: "country",
      label: t("general.country"),
      display: (value: string) => {
        return i18nIsoCountries.getName(value, i18n.locale) as string;
      },
    });
    // Fecha de alta.
    this.exportTable.addHeader({
      key: "createdAt",
      label: t("user.eDate"),
      display: (value: string) => {
        return this.dateFormat(value);
      },
    });
    // Dispositivo.
    this.exportTable.addHeader({
      key: "pairingType",
      label: t("user.device"),
      display: (value: string) => {
        if (value === "BOTH") {
          return t("user.deviceType.both");
        } else if (value === "WIFI") {
          return t("user.deviceType.wifi");
        } else if (value === "NO_WIFI") {
          return t("user.deviceType.nowifi");
        } else {
          return t("user.deviceType.none");
        }
      },
    });
    // Pago.
    this.exportTable.addHeader({
      key: "activePremiumSubscription",
      label: t("user.payment"),
      display: (value: string) => {
        if (value === "true") {
          return t("general.yes");
        } else {
          return t("general.no");
        }
      },
      styles: (value: string, cell: ExportCell) => {
        cell.setColor(this.colorWhite, ExportType.PDF);
        if (value === "true") {
          cell.setBgColor(this.colorOk);
        } else {
          cell.setBgColor(this.colorKo);
        }
        cell.setHorizontalAlign(HorizontalAlign.CENTER);
      },
    });
    // Origen.
    this.exportTable.addHeader({
      key: "provider",
      label: t("user.provider"),
      display: (value: string) => {
        if (value === "BLUE") {
          return "Blue";
        } else if (value === "OPENDIT") {
          return "Opendit";
        } else {
          return value;
        }
      },
    });
    // Política de privacidad.
    this.exportTable.addHeader({
      key: "acceptPrivacy",
      label: t("user.privacy"),
      display: (value: string) => {
        if (value === "true") {
          return t("user.accepted");
        } else {
          return t("user.notAccepted");
        }
      },
      styles: (value: string, cell: ExportCell) => {
        cell.setColor(this.colorWhite, ExportType.PDF);
        if (value === "true") {
          cell.setBgColor(this.colorOk);
        } else {
          cell.setBgColor(this.colorKo);
        }
        cell.setHorizontalAlign(HorizontalAlign.CENTER);
      },
    });
    // Compartir datos/promociones.
    this.exportTable.addHeader({
      key: "acceptSharing",
      label: t("user.data"),
      display: (value: string) => {
        if (value === "true") {
          return t("user.accepted");
        } else {
          return t("user.notAccepted");
        }
      },
      styles: (value: string, cell: ExportCell) => {
        cell.setColor(this.colorWhite, ExportType.PDF);
        if (value === "true") {
          cell.setBgColor(this.colorOk);
        } else {
          cell.setBgColor(this.colorKo);
        }
        cell.setHorizontalAlign(HorizontalAlign.CENTER);
      },
    });
  }
}
