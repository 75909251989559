import { Vue, Component, Prop } from "vue-property-decorator";
import PairingController from "@/domain/pairing/PairingController";
import PairingStatus from "@/views/Devices/DeviceDetails/PairingStatus/PairingStatus.vue";
import DateHelper from "@/ui/helpers/DateHelper";
import SubscriptionWifiController from "@/domain/subscriptionWifi/SubscriptionWifiController";
import AppDeviceIcon from "@/components/AppDeviceIcon.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import PhoneDetailsDialog from "./PhoneDetailsDialog/PhoneDetailsDialog.vue";
import PairingDeviceEntity from "@/domain/pairing/PairingDeviceEntity";
import DeviceCacheReaderController from "@/domain/deviceCacheReader/DeviceCacheReaderController";
import DelMasterDialog from "@/views/Devices/DeviceDetails/PairedUserTable/DelMasterDialog/DelMasterDialog.vue";
import DeviceIcon from "@/ui/components/DeviceIcon/DeviceIcon.vue";

@Component({
  components: {
    PairingStatus,
    AppDeviceIcon,
    AppListMenu,
    PhoneDetailsDialog,
    DelMasterDialog,
    DeviceIcon,
  },
})
export default class PairedDeviceTable extends Vue {
  @Prop({ default: "" }) public userId!: string;
  @Prop() public pairing!: PairingDeviceEntity;
  dateHelper = new DateHelper();
  visiblePhoneDetailDialog = false;
  visibleDelMasterDialog = false;

  /** Registros de la tabla. */
  public dataTable: PairingDeviceEntity[] = [];
  /** Indica si la tabla está cargando datos. */
  public dataTableLoading = false;
  /** Registro seleccionado para mostrar los detalles del teléfono. */
  public pairingSelected: PairingDeviceEntity | null = null;

  public pairingIdDelUserDialog = "";
  public emailDelUserDialog = "";

  mounted() {
    this.loadData();
  }

  /** Carga los datos de la tabla. */
  private async loadData() {
    this.dataTableLoading = true;
    const ctrlPairing = new PairingController();
    ctrlPairing.getPairingByUserId(this.userId).then(async (data) => {
      const ctrlSubscriptionWifi = new SubscriptionWifiController();
      // Recorre cada registro para añadir la información del dispositivo y la suscripción.
      for (const pairing of data) {
        const ctrlDeviceCacheReader = new DeviceCacheReaderController();
        await ctrlDeviceCacheReader
          .getDeviceById(pairing.deviceId)
          .then((data) => {
            pairing.setDeviceCacheReader(data);
          })
          .catch(() => {
            null;
          });
        if (pairing.isWifi()) {
          await ctrlSubscriptionWifi
            .getSubscriptionByDeviceIdUserId(pairing.deviceId, pairing.userId)
            .then((data) => {
              pairing.setSubscriptionWifi(data);
            })
            .catch(() => {
              null;
            });
        }
      }

      // Se quita los registros que no tienen dispositivo asociado.
      //data = data.filter((pairing) => pairing.existsDeviceCacheReader());

      // Se ordena la tabla por el campo master.
      this.dataTable = data.sort((a, b) =>
        a.master === b.master ? 0 : a.master ? -1 : 1
      );
      this.dataTableLoading = false;
    });
  }

  /**
   * Obtiene las opciones del menú de cada registro de la tabla.
   * De forma reactiva para que se traduzcan al cambiar el idioma.
   */
  get tableMenuOptions() {
    return [
      {
        icon: "info",
        text: this.$t("userdetails.subscriptionInfo.title"),
        action: (item: PairingDeviceEntity) => {
          this.$router.push({
            name: "UserSubscriptionDetails",
            params: { email: item.email, pairingId: item.id },
          });
        },
        allowed: true,
        allowedForItem: (item: PairingDeviceEntity) =>
          item.existsSubscription(),
      },
      {
        icon: "video_label",
        text: this.$t("menu.deviceDetails"),
        to: (item: PairingDeviceEntity) => ({
          name: "DeviceDetails",
          params: { deviceId: item.deviceId },
        }),
        allowed: this.$ability.can("details", "devices"),
        allowedForItem: (item: PairingDeviceEntity) =>
          item.existsDeviceCacheReader(),
      },
      {
        icon: "perm_device_information",
        text: this.$t("userdetails.detailsPhone"),
        action: (item: PairingDeviceEntity) => {
          this.pairingSelected = item;
          this.visiblePhoneDetailDialog = true;
        },
        allowed: true,
      },
      {
        icon: "delete",
        text: this.$t("userdetails.removePairing"),
        action: (item: PairingDeviceEntity) => {
          this.pairingIdDelUserDialog = item.id;
          this.emailDelUserDialog = item.email;
          this.visibleDelMasterDialog = true;
        },
        allowed: true,
        allowedForItem: (item: PairingDeviceEntity) => item.canDeleteMaster(),
      },
    ];
  }

  /**
   * Obtiene las cabeceras de la tabla.
   * De forma reactiva para que se traduzcan al cambiar el idioma.
   */
  get dataHeaders() {
    return [
      { text: "", sortable: false },
      { text: this.$t("userdetails.deviceTag"), sortable: false },
      { text: this.$t("userdetails.deviceid"), sortable: false },
      { text: this.$t("userdetails.rol"), sortable: false },
      { text: this.$t("general.status"), sortable: false },
      { text: this.$t("user.pairingDate"), sortable: false },
      { text: this.$t("userdetails.deviceSubscription"), sortable: false },
      { text: "", sortable: false },
    ];
  }

  public onDelUser() {
    this.loadData();
  }
}
