import { render, staticRenderFns } from "./DeviceTabItem.html?vue&type=template&id=3b40cf0e&scoped=true&lang=html&external"
import script from "./DeviceTabItem.ts?vue&type=script&lang=ts&external"
export * from "./DeviceTabItem.ts?vue&type=script&lang=ts&external"
import style0 from "./DeviceTabItem.vue?vue&type=style&index=0&id=3b40cf0e&prod&scoped=true&lang=css"
import style1 from "./DeviceTabItem.vue?vue&type=style&index=1&id=3b40cf0e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b40cf0e",
  null
  
)

export default component.exports