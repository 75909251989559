import {
  FirmwareHWCompatibleRes,
  FirmwareSubtype,
} from "@/interfaces/firmwares";
import { FirmwareService } from "../services/FirmwareService";
import { AxiosError, AxiosProgressEvent } from "axios";
import { FirmwareEntity, FirmwareInterface } from "../entities/FirmwareEntity";

/**
 * @deprecated Esta clase está en desuso y se eliminará en versiones futuras.
 * Utilice domain/Firmware/FirmwareController en su lugar.
 */
export class FirmwareController {
  /** Servicio de firmware. */
  protected service: FirmwareService;

  constructor() {
    this.service = new FirmwareService();
  }

  /** Obtiene la información del subtipo. */
  public async getFwSubType(
    family: string,
    type: string,
    subtype: string
  ): Promise<FirmwareSubtype> {
    return await this.service.getFwSubType(family, type, subtype);
  }

  /** Sube un fichero con el Firmware. */
  public async uploadFirmware(
    sha: string,
    file: File,
    uploadFileProgress: (progressEvent: AxiosProgressEvent) => void
  ): Promise<FirmwareInterface> {
    return await this.service
      .uploadFirmware(sha, file, uploadFileProgress)
      .catch((error: AxiosError) => {
        const status = error.response?.status;
        switch (status) {
          case 400:
            // BAD_REQUEST
            throw new Error("firmware.error.wrongName");
          case 406:
            // NOT_ACCEPTABLE
            throw new Error("firmware.error.wrongSha");
          case 409:
            // CONFLICT
            throw new Error("firmware.error.fwRegistered");
          default:
            throw new Error("firmware.error.notUploaded");
        }
      });
  }

  /** Obtiene un listado de firmwares compatibles con la familia, typo y subtipo. */
  public async getFwHwCompatible(
    family: string,
    type: string,
    subtype: string
  ): Promise<FirmwareHWCompatibleRes> {
    return await this.service
      .getFwHwCompatible(family, type, subtype)
      .then((res: FirmwareHWCompatibleRes) => {
        if (!res) {
          return {
            family: "",
            type: "",
            subtype: "",
            hwList: [],
          };
        }
        // El back lo trabaja como entero pero en el front se visualiza como string.
        // Esta decisión se tomó en la tarea SWU-6541.
        for (let i = 0; i < res.hwList.length; i++) {
          if (res.hwList[i] === "0") {
            res.hwList[i] = "00";
          } else if (res.hwList[i] === "1") {
            res.hwList[i] = "01";
          }
        }
        return res;
      });
  }

  /** Devuelve si ya existe una familia (tipo y subtipo), con ese HW que tenga marcado la actualización automática. */
  public async existAutomaticUpdate(
    family: string,
    type: string,
    subtype: string,
    hw: string
  ): Promise<boolean> {
    return await this.service.existAutomaticUpdate(family, type, subtype, hw);
  }

  /** Devuelve si ya existe una familia (tipo y subtipo), con ese HW que tenga marcado el más reciente. */
  public async existLatest(
    family: string,
    type: string,
    subtype: string,
    hw: string
  ): Promise<boolean> {
    return await this.service.existLatest(family, type, subtype, hw);
  }

  /** Actualiza los datos del firmware, eso se utiliza para completar el uploadfile. */
  public async update(firmwareUpload: FirmwareEntity): Promise<FirmwareEntity> {
    return await this.service
      .update(firmwareUpload)
      .catch((error: AxiosError) => {
        const status = error.response?.status;
        switch (status) {
          case 400:
            // BAD_REQUEST
            throw new Error("firmware.error.wrongName");
          case 404:
            // NOT_FOUND
            throw new Error("firmware.error.notFound");
          default:
            throw new Error("firmware.error.notUpdated");
        }
      });
  }

  /** Elimina un firmware. */
  public async delete(id: string): Promise<void> {
    return await this.service.delete(id).catch((error: AxiosError) => {
      const status = error.response?.status;
      if (status === 404) {
        // NOT_FOUND
        /* Si no lo ha encontrado, es que no existe.
        A vista del usuario, está eliminado. */
        return;
      } else {
        throw error;
      }
    });
  }

  /** obtiene el valor de conectable para los Fw, pasando solo la familia. Es posible pasar tambien type y subType. */
  public async isConnectable(
    family: string,
    type: string,
    subtype: string
  ): Promise<boolean> {
    return await this.service.isConnectable(family, type, subtype);
  }
}
