import ExportCell from "@/domain/Export/ExportCell";
import { translateText as t, translatePlural as tc } from "@/lang/i18n";
import ExportBase from "./ExportBase";
export default class ExportInstallations<T> extends ExportBase<T> {
  constructor(idTab: number) {
    super(t("installation.installations"));
    this.generateHeaders(idTab);
  }

  private generateHeaders(idTab: number): void {
    // Identificador.
    this.exportTable.addHeader({
      key: "id",
      label: t("installation.id"),
    });
    // Tag.
    this.exportTable.addHeader({
      key: "tag",
      label: t("installation.tag"),
    });
    // Estado.
    this.exportTable.addHeader({
      key: "status",
      label: t("installation.status"),
      display: (value: string) => {
        if (value === "CONNECTED") {
          return t("installation.online");
        } else if (value === "DISCONNECTED") {
          return t("installation.offline");
        } else if (value === "WARNING") {
          return t("installation.warning");
        } else {
          return "";
        }
      },
      styles: (value: string, cell: ExportCell) => {
        cell.setColor(this.colorWhite);
        if (value === "CONNECTED") {
          cell.setBgColor(this.colorOk);
        } else if (value === "DISCONNECTED") {
          cell.setBgColor(this.colorDisconnected);
        } else if (value === "WARNING") {
          cell.setBgColor(this.colorWarning);
        }
      },
    });
    // Ciudad.
    this.exportTable.addHeader({
      key: "city",
      label: t("installation.city"),
    });
    // Fecha de registro.
    this.exportTable.addHeader({
      key: "createdAt",
      label: t("installation.createdAt"),
      display: (value: string) => {
        return this.dateFormat(value);
      },
    });
    // Última fecha de actualización.
    this.exportTable.addHeader({
      key: "lastTelemetryDate",
      label: t("installation.lastTelemetryDate"),
      display: (value: string) => {
        return this.dateFormat(value);
      },
    });
    // Señal.
    this.exportTable.addHeader({
      key: "signal",
      label: t("installation.coverage"),
      display: (value: string) => {
        return value + " %";
      },
    });
    // Total de viviendas.
    this.exportTable.addHeader({
      key: "penetration.numberTotalUnits",
      label: t("installation.penetrationDialog.totalHomes"),
    });
    // Emparejadas.
    this.exportTable.addHeader({
      key: "penetration.numberDevicesWithActiveSubscription",
      label: t("installation.penetrationDialog.paired"),
    });
    // Ratio de adopción.
    this.exportTable.addHeader({
      key: "penetration.activeSubscriptionPercentage",
      label: t("installation.penetrationDialog.adoptionRate"),
      display: (value: string) => {
        return value + " %";
      },
    });
    // Suscripciones de pago.
    this.exportTable.addHeader({
      key: "penetration.premiumSubscriptionNumber",
      label: t("installation.penetrationDialog.paidSubscriptions"),
    });
    // Ratio de suscripción.
    this.exportTable.addHeader({
      key: "penetration.premiumSubscriptionPercentage",
      label: t("installation.penetrationDialog.subscriptionRatio"),
      display: (value: string) => {
        return value + " %";
      },
    });
    if (idTab === 0) {
      // Placas.
      this.exportTable.addHeader({
        key: "panels",
        label: t("installationDetails.panels"),
      });
      // Monitores no wifi.
      this.exportTable.addHeader({
        key: "nonConnectableMonitors",
        label: tc("installationDetails.device.monitorNoWifi", 2),
        display: (value: string) => {
          return value || "0";
        },
      });
      // Monitores wifi.
      this.exportTable.addHeader({
        key: "connectableMonitors",
        label: tc("installationDetails.device.monitorWifi", 2),
        display: (value: string) => {
          return value || "0";
        },
      });
      // Teléfonos.
      this.exportTable.addHeader({
        key: "phones",
        label: t("installationDetails.phones"),
      });
      // Conserjería.
      this.exportTable.addHeader({
        key: "gu",
        label: t("installationDetails.gu"),
      });
    } else {
      // Ediboxes.
      this.exportTable.addHeader({
        key: "ediboxes",
        label: t("installationDetails.ediboxes"),
      });
      // Monitores.
      this.exportTable.addHeader({
        key: "monitors",
        label: t("installationDetails.monitors"),
      });
    }
  }
}
