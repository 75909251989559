import SubscriptionNoWifiEntity from "./SubscriptionNoWifiEntity";
import SubscriptionNoWifiService from "./SubscriptionNoWifiService";

export default class SubscriptionNoWifiController {
  protected service: SubscriptionNoWifiService;

  constructor() {
    this.service = new SubscriptionNoWifiService();
  }

  /**
   * Activar periodo de prueba.
   * Debe estar en plan gratuito o dará error.
   * @param userId Id del usuario.
   * @param loginalId Id de la instalación
   * @param expiredDate Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  public async activateTrial(
    userId: string,
    loginalId: string,
    expiredDate: string,
    reason: string,
    demoDays: number
  ): Promise<SubscriptionNoWifiEntity> {
    const days = this.getDaysDiff(expiredDate);
    return this.service
      .activateTrial(userId, loginalId, reason)
      .then(async (data) => {
        if (days === demoDays) {
          // Se ha enviado la petición para activar el trial, pero tarda unos milisegundos en activarse.
          await new Promise((resolve) => setTimeout(resolve, 2000));
          return new SubscriptionNoWifiEntity(data);
        } else {
          return await this.extendTrial(loginalId, expiredDate, reason);
        }
      });
  }

  /**
   * Extender el periodo de pruerba.
   * Debe estar en plan trial, o con un trial expirado sino dará error.
   * @param logicalId Id de la instalación
   * @param expiredDate Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  public async extendTrial(
    logicalId: string,
    expiredDate: string,
    reason: string
  ): Promise<SubscriptionNoWifiEntity> {
    return this.service
      .extendTrial(logicalId, expiredDate, reason)
      .then(async (data) => {
        // Se ha enviado la petición para extender el trial, pero tarda unos milisegundos en extenderse.
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return new SubscriptionNoWifiEntity(data);
      });
  }

  /**
   * Calcular la diferencia de días entre la fecha actual y la fecha de expiración.
   * @param expiredDateISO Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  private getDaysDiff(expiredDateISO: string): number {
    const currentDate = new Date();
    const expiredDate = new Date(expiredDateISO);
    const differenceInTime = expiredDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }
}
