import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jobService from "@/api/job";

import AppDeviceIcon from "@/components/AppDeviceIcon.vue";

import FirmwareVersionSelect from "@/views/Firmware/FirmwareVersionSelect/FirmwareVersionSelect.vue";

import { InsParamsFWOptions } from "@/interfaces/installations";
import DeviceIcon from "@/ui/components/DeviceIcon/DeviceIcon.vue";
import { FirmwareEntity } from "@/domain/entities/FirmwareEntity";
import {
  DeviceInsFwSelEntity,
  InsFilInsFwSelEntity,
} from "./InstallationFwsSelectorModal";
import { InsFilInsFwSelInterface } from "./InstallationFwsSelectorInterface";
import { FirmwareController } from "@/domain/Firmware/FirmwareController";
import FirmwareVersionV2Entity from "@/domain/Firmware/FirmwareVersionV2Entity";

@Component({
  components: {
    AppDeviceIcon,
    FirmwareVersionSelect,
    DeviceIcon,
  },
})
export default class InstallationFwsSelector extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Array) installation!: Array<any>;
  @Prop(Array) selectedDevices!: Array<string>;
  @Prop(Array) selectedFws!: Array<FirmwareEntity>;
  @Prop({ type: Boolean, default: false }) downgrade!: boolean;

  public installationFiltered: Array<InsFilInsFwSelEntity> = [];
  public numPanelOpen = 0;

  mounted() {
    this.loadData();
  }

  @Watch("downgrade")
  onChangeDowngrade(newValue: boolean) {
    this.installationFiltered.map((inst) => (inst.downgrade = newValue));
  }

  // #region Carga de datos
  private loadData() {
    if (this.installation.length > 0) {
      // Formatea los datos.
      this.loadInstallationFiltered();
      // Marca los "jobOn".
      this.loadDevicesWithJobOn();
      this.loadInstallationFirmwareOptions();
    }
  }

  /**
   * Filtra las instalaciones para obtener solo las placas y monitores.
   * Luego, mapea los datos para obtener un objeto con los datos necesarios.
   */
  private loadInstallationFiltered() {
    const dataFiltered = this.installation.filter(
      (i) => i.family === "PANEL" || i.family === "MONITOR"
    );
    this.installationFiltered = dataFiltered.map((i) => {
      const params: InsFilInsFwSelInterface = {
        devices: i.devices.map((d) => {
          const de = new DeviceInsFwSelEntity();
          de.serialNumber = d.serialNumber;
          de.family = d.family;
          de.type = d.type;
          de.subtype = d.subtype;
          de.versionHW = d.versionHW;
          de.fwVersionV2 = new FirmwareVersionV2Entity(d.fwVersionV2);
          de.changeSelected = this.updateDevices.bind(this);
          return de;
        }),
        family: i.family,
        subtype: i.subtype,
        type: i.type,
        optionsFirmware: [],
        downgrade: this.downgrade,
      };
      const entity = new InsFilInsFwSelEntity(params);
      entity.changeFirmware = this.updateFirmwares.bind(this);
      return entity;
    });
  }

  /**
   * Obtiene los dispositivos que tienen un trabajo en curso.
   * y los marca como "jobOn".
   */
  private loadDevicesWithJobOn() {
    // Extrae los números de serie de los "devices" de "this.installation".
    const serialNumbers = this.installation.flatMap((i) =>
      i.devices.map((d) => d.serialNumber)
    );
    // Obtiene los dispositivos con trabajo en curso.
    jobService.getDevicesWithJobOn(serialNumbers).then((res) => {
      const data: Record<string, string> = res.data;
      if (Object.keys(res.data).length !== 0) {
        /* Mira dentro de los "devices" de "this.installation",
         * si el "serialNumber" está en alguno de ellos,
         * marca el "device" como "jobOn".
         */
        for (const serialNumber in data) {
          for (const instFirm of this.installationFiltered) {
            for (const device of instFirm.devices) {
              if (device.serialNumber === serialNumber) {
                device.jobOn = true;
              }
            }
          }
        }
      }
    });
  }

  /**
   * Obtiene las opciones de firmware para cada grupo de dispositivos.
   */
  loadInstallationFirmwareOptions() {
    for (const instFirm of this.installationFiltered) {
      instFirm.optionsFirmware = [];
      instFirm.selectedFirmware = undefined;
      const params: InsParamsFWOptions = {
        family: instFirm.family,
        type: instFirm.type,
        subtype: instFirm.subtype,
        versionHW: instFirm.devices.map((d) => d.versionHW),
      };
      const ctrl = new FirmwareController();
      ctrl
        .getFirmwareByDevice(
          params.family,
          params.type,
          params.subtype,
          params.versionHW
        )
        .then((res) => {
          instFirm.optionsFirmware = res;
        });
    }
  }

  // #endregion

  updateFirmwares() {
    const data: Array<FirmwareEntity> = this.installationFiltered.flatMap(
      (i) => {
        if (i.hasSelectedDevices()) {
          return i.selectedFirmware ? [i.selectedFirmware] : [];
        }
        return [];
      }
    );
    this.$emit("update:selectedFws", data);
  }

  updateDevices() {
    const data: Array<string> = this.installationFiltered.flatMap((i) =>
      i.devices.filter((d) => d.selected).map((d) => d.serialNumber)
    );
    this.updateFirmwares();
    this.$emit("update:selectedDevices", data);
  }

  goToInstallation(serialNumber: string) {
    window.open(
      (process.env.VUE_APP_PUBLIC_PATH || "") +
        process.env.VUE_APP_I18N_LOCALE +
        "/device-" +
        serialNumber,
      "_blank"
    );
  }

  public messagesCheckbox(
    firmware: InsFilInsFwSelEntity,
    device: DeviceInsFwSelEntity
  ) {
    if (device.jobOn) {
      return this.$t("installationFwsSelector.errorMessages.jobOn");
    }
    if (!firmware.selectedFirmware) {
      return this.$t("installationFwsSelector.errorMessages.noFirmware");
    }
    if (!firmware.selectedFirmware.hwListIncludes([device.versionHW])) {
      return this.$t(
        "installationFwsSelector.errorMessages.incompatibleFirmware"
      );
    }

    const fwDevice = new FirmwareVersionV2Entity(device.fwVersionV2);
    const fwSelect = new FirmwareVersionV2Entity(
      firmware.selectedFirmware?.firmwareVersionV2 || {
        fwMajor: "",
        fwMinor: "",
        fwBuild: "",
      }
    );
    if (!firmware.downgrade) {
      if (fwDevice.isHigherThan(fwSelect)) {
        return this.$t("installationFwsSelector.errorMessages.fwHigher", [
          fwDevice.getFirmwareVersion(),
        ]);
        /*} else if (fwDevice.isLowerThan(fwSelect)) {
        return this.$t("installationFwsSelector.errorMessages.fwLower", [
          fwDevice.getFirmwareVersion(),
        ]);*/
      } else if (fwDevice.isEqual(fwSelect)) {
        return this.$t("installationFwsSelector.errorMessages.fwEquals", [
          fwDevice.getFirmwareVersion(),
        ]);
      }
    }
  }
}
