import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import FrmxInputText from "@/ui/components/FrmxInputText/FrmxInputText.vue";
import FrmxButton from "@/ui/components/FrmxButton/FrmxButton.vue";

import SubscriptionNoWifiController from "@/domain/subscriptionNoWifi/SubscriptionNoWifiController";
import { AxiosError } from "axios";

@Component({
  components: { FrmxDialog, FrmxInputText, FrmxButton },
})
export default class ActivateTrialDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop({ default: "" }) public userId!: string;
  @Prop({ default: "" }) public logicalId!: string;

  public visibleDialog = false;
  public visibleDialogConfirm = false;
  public visibleDialogOk = false;
  public visibleDialogko = false;
  public errorMessage = "";
  public loading = false;

  /** Valor por defecto de 30 días. */
  public picker = this.getDemoDaysDays();
  /** Valor mínimo los 30 días que hay por defecto. */
  public minPicker = this.picker;
  /** Valor máximo un año desde la fecha actual. */
  public maxPicker = this.get365Days();
  /** Días de diferencia entre la fecha actual y la fecha seleccionada. */
  public daysDialogConfirm = 0;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  mounted() {
    this.visibleDialog = this.value;
  }

  public onClickClose() {
    this.close();
  }

  public onClickSave() {
    this.onVisibleDialogChange(false);
    this.daysDialogConfirm = this.getDaysDiff(this.picker);
    this.visibleDialogConfirm = true;
  }

  public onClickCancel() {
    this.close();
  }

  public onClickConfirm() {
    this.loading = true;
    if (this.isFormOk()) {
      this.activateTrial();
    } else {
      this.loading = false;
    }
  }

  private activateTrial() {
    this.loading = true;
    const ctrl = new SubscriptionNoWifiController();
    ctrl
      .activateTrial(
        this.userId,
        this.logicalId,
        this.picker,
        this.reasonValue,
        this.getDemoDays()
      )
      .then(() => {
        this.visibleDialogConfirm = false;
        this.visibleDialogOk = true;
        this.$emit("trialActivated");
      })
      .catch(this.activeTrialHandleError)
      .finally(() => {
        this.loading = false;
      });
  }

  private isFormOk() {
    return this.reasonErrors.length === 0;
  }

  private close() {
    this.onVisibleDialogChange(false);
    this.visibleDialogConfirm = false;
    this.visibleDialogOk = false;
    this.visibleDialogko = false;
  }

  /** Manejar errores al añadir un invitado. */
  private activeTrialHandleError(
    error: AxiosError<{
      title: string;
      status: number;
      detail: string;
    }>
  ) {
    this.errorMessage = "";
    if (error.response?.data.status === 500) {
      this.errorMessage = "Internal server error. Please try again later.";
    } else {
      this.errorMessage = error.response?.data.detail ?? "";
    }
    this.visibleDialogConfirm = false;
    this.visibleDialogko = true;
  }

  private getDemoDays(): number {
    return Number(this.$t("env.demoDays"));
  }

  /**
   * Obtener la fecha actual más 30 días.
   * En formato YYYY-MM-DD.
   */
  private getDemoDaysDays(): string {
    return new Date(
      new Date().setDate(new Date().getDate() + this.getDemoDays())
    )
      .toISOString()
      .split("T")[0];
  }

  /**
   * Obtener la fecha actual más 365 días.
   * En formato YYYY-MM-DD.
   */
  private get365Days(): string {
    return new Date(new Date().setDate(new Date().getDate() + 365))
      .toISOString()
      .split("T")[0];
  }

  /**
   * Calcular la diferencia de días entre la fecha actual y la fecha de expiración.
   * @param expiredDateISO Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  private getDaysDiff(expiredDateISO: string): number {
    const currentDate = new Date();
    const expiredDate = new Date(expiredDateISO);
    const differenceInTime = expiredDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  // #region Motivo de la invitación.
  public reasonValue = "";
  public reasonErrors: Array<string> = [];

  public onChangeReason() {
    this.validateReason();
  }

  public validateReason() {
    if (!this.reasonValue) {
      this.reasonErrors = [this.$t("general.inputErrors.required").toString()];
    } else {
      this.reasonErrors = [];
    }
  }
  // #endregion
}
