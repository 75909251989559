import { Workbook, Worksheet } from "exceljs";

export default class ExportTableExcel {
  private workbook: Workbook;
  private worksheet: Worksheet;
  private headers: string[] = [];
  private data: string[][] = [];

  constructor(title: string) {
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet(title);
  }

  public addHeader(header: string): void {
    this.headers.push(header);
  }

  public addDatum(data: string[]): void {
    this.data.push(data);
  }

  public async download(fileName: string) {
    this.worksheet.addRow(this.headers);
    this.worksheet.addRows(this.data);
    await this.downloadFile(fileName);
  }

  /** Descarga el archivo CSV con los datos de la tabla. */
  private async downloadFile(fileName: string) {
    // Generar el archivo CSV con configuración personalizada
    await this.workbook.csv
      .writeBuffer({
        formatterOptions: {
          delimiter: ";", // Especificar el separador como punto y coma
          quote: true, // Añadir comillas alrededor de los valores
          rowDelimiter: "\r\n", // Especificar el delimitador de fila CR LF
        },
        encoding: "utf8", // Especificar la codificación UTF-8
      }) // Especificar el separador ";"
      .then((data) => {
        // Añadir el BOM (Byte Order Mark) manualmente
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        // Crear un Blob a partir del Buffer
        const blob = new Blob([bom, data], {
          type: "text/csv;charset=utf-8", // Especificar el tipo MIME
        });

        // Crear una URL para el Blob
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace para descargar el Blob
        const enlace = document.createElement("a");
        enlace.download = fileName + ".csv";
        enlace.href = url;

        // Simular un clic en el enlace para iniciar la descarga
        enlace.click();

        // Liberar la URL del Blob
        window.URL.revokeObjectURL(url);
      });
  }
}
