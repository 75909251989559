export default class SessionStorageHelper<T> {
  private key: string;
  private defaultValue: T;
  constructor(key: string, defaultValue: T) {
    this.key = key;
    this.defaultValue = defaultValue;
  }

  public setItem(value: T): void {
    sessionStorage.setItem(this.key, JSON.stringify(value));
  }

  public getItem(): T {
    const value = sessionStorage.getItem(this.key);
    return value === null ? this.defaultValue : JSON.parse(value);
  }
}
