import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import UserController from "@/domain/users/UserController";
import PhoneInfoEntity from "@/domain/users/PhoneInfoEntity";

@Component({
  components: { FrmxDialog },
})
export default class PhoneSessionHistoryDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop({ default: "" }) public userId!: string;

  public visibleDialog = false;
  public loading = false;
  public data: PhoneInfoEntity[] = [];

  @Watch("value")
  private onValueChange(value: boolean) {
    if (value === true) {
      this.loadData();
    }
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  @Watch("userId")
  private onUserIdChange() {
    this.loadData();
  }

  /** Carga los datos. */
  private async loadData() {
    this.data = [];
    if (!this.userId) {
      return;
    }
    this.loading = true;
    const ctrl = new UserController();
    ctrl
      .getPhoneInfo(this.userId)
      .then((data) => {
        console.log(data);
        this.data = data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
