<template>
  <v-layout :class="classStyle">
    <v-menu
      ref="menu"
      v-model="showMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
      style="width: 100%; position: absolute"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="content"
          :label="label"
          readonly
          v-on="on"
          :clearable="!disabled"
          :disabled="disabled"
          :rules="rules"
        >
          <template v-slot:append>
            <v-btn icon small @click="showMenu = true" v-if="!disabled">
              <v-icon :size="18">event</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="content"
        scrollable
        :max="max"
        :min="min"
        :key="content"
        :show-current="showCurrent"
      />
    </v-menu>
  </v-layout>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class AppInputDate extends Vue {
  declare $moment;

  @Prop(String) classStyle!: string;
  @Prop(String) label!: string;
  @Prop(String) value!: string;
  @Prop(String) min!: string;
  @Prop(String) max!: string;
  @Prop(Array) readonly rules!: Array<any>;
  @Prop(Boolean) disabled!: boolean;

  public content = "";
  public showMenu = false;

  created() {
    this.content = this.value || "";
  }

  public get showCurrent() {
    return (
      (this.content && this.$moment(this.content).format("YYYY-MM-DD")) ||
      this.$moment().format("YYYY-MM-DD")
    );
  }

  @Watch("value")
  onChangeValue() {
    this.showMenu = false;
    if (this.content !== this.value) this.content = this.value;
  }

  @Watch("content")
  onChangeContent() {
    this.$emit("input", this.content);
  }
}
</script>
