import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";

export default class ExportInternalUsers<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.internalUsers"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Correo electrónico.
    this.exportTable.addHeader({
      key: "email",
      label: t("user.email"),
    });
    // Roles.
    this.exportTable.addHeader({
      key: "rolesString",
      label: t("oauthclients.rol"),
    });
  }
}
