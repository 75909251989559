import UserService from "./UserService";
import PhoneInfoEntity from "./PhoneInfoEntity";

export default class UserController {
  protected service: UserService;

  constructor() {
    this.service = new UserService();
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    return this.service.getUserIdByEmail(email);
  }

  public async getPhoneInfo(userId: string): Promise<PhoneInfoEntity[]> {
    return this.service.getPhoneInfo(userId).then((data) => {
      return data.map((item) => new PhoneInfoEntity(item));
    });
  }
}
