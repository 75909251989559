import ExportCell from "@/domain/Export/ExportCell";
import { ExportType } from "@/domain/Export/ExportHeaderInterface";
import ExportTable from "@/domain/Export/ExportTable";
import { translateText as t } from "@/lang/i18n";
import moment from "moment";

export default class ExportBase<T> {
  protected exportTable: ExportTable<T>;

  protected colorWhite = "FFFFFF";
  protected colorOk = "4caf50";
  protected colorKo = "f44336";
  protected colorDisconnected = "616161";
  protected colorGray = "e0e0e0";
  protected colorWarning = "ffa000";

  protected constructor(title: string) {
    this.exportTable = new ExportTable(title);
  }

  public addData(data: T[]) {
    this.exportTable.addData(data);
  }
  public download(type: ExportType) {
    this.exportTable.download(type);
  }

  protected dateFormatUnix(timestamp: number): string {
    if (!timestamp) {
      return "";
    }
    return moment.unix(timestamp).format("DD/MM/YYYY HH:mm");
  }

  protected dateFormat(date: string | number): string {
    if (!date || date === "") {
      return "";
    }
    return moment(date).format("DD/MM/YYYY HH:mm");
  }

  protected displayYesNo(value: string): string {
    return value === "true" ? t("general.yes") : t("general.no");
  }

  protected stylesYesNo(value: string, cell: ExportCell): void {
    cell.setColor(this.colorWhite);
    if (value === "true") {
      cell.setBgColor(this.colorOk);
    } else {
      cell.setBgColor(this.colorKo);
    }
  }
}
