import {
  FirmwareHWCompatibleRes,
  FirmwareSubtype,
} from "@/interfaces/firmwares";
import { AxiosProgressEvent } from "axios";
import { FirmwareEntity, FirmwareInterface } from "../entities/FirmwareEntity";
import { AxiosHelper } from "../helpers/AxiosHelper";

/**
 * @deprecated Esta clase está en desuso y se eliminará en versiones futuras.
 * Utilice domain/Firmware/FirmwareService en su lugar.
 */
export class FirmwareService {
  protected axiosService: AxiosHelper;
  private urlApiV1: string;
  private urlApiV2: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlApiV1 = process.env.VUE_APP_PRE_URI + "/firmware/api/v1";
    this.urlApiV2 = process.env.VUE_APP_PRE_URI + "/firmware/api/v2";
  }

  /** Obtiene la información del subtipo. */
  public async getFwSubType(
    family: string,
    type: string,
    subtype: string
  ): Promise<FirmwareSubtype> {
    const url =
      this.urlApiV1 +
      "/family/" +
      family +
      "/type/" +
      type +
      "/subtype/" +
      subtype;
    return this.axiosService.get(url);
  }

  /** Sube un fichero de firware y lo deja pendiente. */
  public async uploadFirmware(
    sha: string,
    file: File,
    uploadFileProgress: (progressEvent: AxiosProgressEvent) => void
  ): Promise<FirmwareInterface> {
    const url = this.urlApiV2 + "/firmware" + "?sha256=" + sha;
    return this.axiosService
      .uploadFile(url, file, uploadFileProgress)
      .then((res) => {
        return res.dataFirmwareDTO.firmwareDto;
      });
  }

  /** Obtiene un listado de firmwares compatibles con la familia, typo y subtipo.
   * Si es un PANEL, puede tener el type "CORA" con lo cual hay que buscar por type.
   */
  public async getFwHwCompatible(
    family: string,
    type: string,
    subtype: string
  ): Promise<FirmwareHWCompatibleRes> {
    let url = this.urlApiV2 + "/firmware-hardware-compatible";
    url += "?family=" + family;
    if (type && subtype && family !== "EDIBOX") {
      url += "&type=" + type + "&subtype=" + subtype;
    } else if (family === "PANEL" && type) {
      url += "&type=" + type;
    }
    return this.axiosService.get(url);
  }

  /** Devuelve si ya existe una familia (tipo y subtipo), con ese HW que tenga marcado la actualización automática. */
  public async existAutomaticUpdate(
    family: string,
    type: string,
    subtype: string,
    hw: string
  ): Promise<boolean> {
    let url = this.urlApiV2 + "/exists-automatic-update";
    url += "?family=" + family;
    if (type && subtype && family !== "PANEL" && family !== "EDIBOX") {
      url += "&type=" + type + "&subtype=" + subtype;
    }
    if (hw !== null) {
      url += "&versionHW=" + hw;
    }
    return this.axiosService.get(url);
  }

  /** Devuelve si ya existe una familia (tipo y subtipo), con ese HW que tenga marcado el más reciente. */
  public async existLatest(
    family: string,
    type: string,
    subtype: string,
    hw: string
  ): Promise<boolean> {
    let url = this.urlApiV2 + "/exists-latest";
    url += "?family=" + family;
    if (type && subtype && family !== "PANEL" && family !== "EDIBOX") {
      url += "&type=" + type + "&subtype=" + subtype;
    }
    if (hw !== null) {
      url += "&versionHW=" + hw;
    }
    return this.axiosService.get(url);
  }

  /** Actualiza los datos del firmware, eso se utiliza para completar el uploadfile. */
  public async update(firmwareUpload: FirmwareEntity): Promise<FirmwareEntity> {
    let url = this.urlApiV2 + "/firmware";
    url += "/" + firmwareUpload.id;
    return this.axiosService.post(url, firmwareUpload).then((res) => {
      return res.dataFirmwareDTO.firmwareDto;
    });
  }

  /** Elimina un firmware. */
  public async delete(id: string): Promise<void> {
    let url = this.urlApiV1 + "/firmware";
    url += "/" + id;
    return this.axiosService.delete(url);
  }

  /** obtiene el valor de conectable para los Fw, pasando solo la familia. Es posible pasar tambien type y subType. */
  public async isConnectable(
    family: string,
    type: string,
    subtype: string
  ): Promise<boolean> {
    let url = this.urlApiV2 + "/isconnectable";
    url += "?family=" + family;
    if (type && subtype) {
      url += "&type=" + type + "&subtype=" + subtype;
    }
    return this.axiosService.get(url);
  }
}
