<template lang="html" src="./MarkersMap.html"></template>

<script>
import mapMixin from "../commons/map.mixin";
import MarkerMixin from "@/mixins/marker.mixin";

import L from "leaflet";
import { LIcon, LPopup, LMarker } from "vue2-leaflet";
import LMarkerCluster from "vue2-leaflet-markercluster";
import "leaflet.markercluster";

import LegendCount from "./LegendCount/LegendCount";
import MapSearch from "./MapSearch/MapSearch";
import MapFilter from "./MapFilter/MapFilter";

export default {
  name: "MarkersMap",

  components: {
    LIcon,
    LPopup,
    LMarker,
    LMarkerCluster,
    LegendCount,
    MapSearch,
    MapFilter,
  },

  mixins: [mapMixin],

  props: {
    search: String,
    hasAnyActiveFilter: Boolean,

    getData: {
      type: Function,
      required: true,
    },
    maxMarkersSizeAllowed: Number,
    markerInfo: {
      type: Object,
      required: true,
      default: () => ({ type: "", getName: null, isOnline: null }),
    },

    areaCode: String,
  },

  data() {
    return {
      onLineCount: 0,
      offLineCount: 0,

      markersRev: 0,
      markers: [],
      clusterOptions: {
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: true,
        polygonOptions: {
          weight: 1.5,
          color: "#220",
          opacity: 0.5,
        },
        iconCreateFunction: this.createClusterIcon,
      },
      popupOptions: {
        closeButton: false,
      },
      markerMixin: new MarkerMixin(),
      tileLayerUrl: null,
      tileLayerAttribution: null,
      mapOptions: null,
    };
  },

  watch: {
    search() {
      this.getMarkers();
    },
  },

  mounted() {
    this.tileLayerUrl = this.markerMixin.tileLayerUrl;
    this.tileLayerAttribution = this.markerMixin.tileLayerAttribution;
    this.mapOptions = this.markerMixin.mapOptions;
    this.getMarkers();
  },

  beforeDestroy() {
    this.markers = [];
    this.markersRev = 0;
  },

  methods: {
    /*applySearch(newSearch) {
      this.getMarkers();

      this.$emit("applySearch", newSearch);
    },*/

    resetOnlineCounts() {
      this.onLineCount = 0;
      this.offLineCount = 0;
    },

    getMarkers() {
      this.setLoading(true);

      this.getData(this.areaCode).then((res) => {
        this.resetOnlineCounts();

        if (
          this.maxMarkersSizeAllowed &&
          res.length > this.maxMarkersSizeAllowed
        ) {
          this.setLoading(false);

          this.$store.dispatch("snackbarInfo", {
            text: this.$t("geo.mapErrorTooManyResults"),
          });

          return;
        }

        this.addMarkers(res);
        this.setLoading(false);
      });
    },

    addMarkers(data) {
      if (data !== null && data !== undefined && data.length > 0) {
        data.forEach((m) => {
          this.markerInfo.isOnline(m)
            ? this.onLineCount++
            : this.offLineCount++;
        });
      }

      this.markers = data;
      this.markersRev = Math.random();
    },

    createClusterIcon(cluster) {
      const childs = cluster.getAllChildMarkers();
      let onLine = 0,
        offLine = 0;
      childs.forEach((child) => {
        this.markerInfo.isOnline(child.options.data) ? onLine++ : offLine++;
      });

      let c = " marker-cluster-";
      c +=
        onLine > 0 && offLine > 0
          ? "online-and-offline"
          : onLine > 0 && offLine === 0
          ? "all-online"
          : "all-offline";

      const childCount = cluster.getChildCount();

      return new L.DivIcon({
        html: "<div><span>" + childCount + "</span></div>",
        className: "marker-cluster" + c,
        iconSize: new L.Point(40, 40),
      });
    },

    getMarkerLatLong(name, marker) {
      return this.markerMixin.getMarkerLatLong(name, marker);
    },
    markerIcon(icon) {
      return this.markerMixin.markerIcon(icon);
    },
  },
};
</script>
<style>
/* Estilo aplicable a los clusters
   Se extraen los colores de la paleta de colores de Vuetify,
   dado que la Toolbar es 'blue darken-4' se aplica como máximo */
.table-markers-map .marker-cluster-all-online {
  background-color: #43a047 !important;
}
.table-markers-map .marker-cluster-all-online div {
  background-color: green !important;
  color: white !important;
}

.table-markers-map .marker-cluster-all-offline {
  background-color: #bdbdbd !important;
}
.table-markers-map .marker-cluster-all-offline div {
  background-color: #616161 !important;
  color: white !important;
}

.table-markers-map .marker-cluster-online-and-offline {
  background-color: #e0e0e0 !important;
}
.table-markers-map .marker-cluster-online-and-offline div {
  background-color: #bdbdbd !important;
}

.table-markers-map .marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.table-markers-map .marker-cluster div {
  width: 31px;
  height: 31px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 11px "Helvetica Neue", Arial, Helvetica, sans-serif;
  /* font-size: smaller */
}
.table-markers-map .marker-cluster span {
  line-height: 30px;
}
</style>
