<template lang="html" src="./FirmwareVersionSelect.html"></template>

<!-- eslint-disable @typescript-eslint/no-empty-function -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { FirmwareEntity } from "@/domain/entities/FirmwareEntity";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class FirmwareVersionSelect extends Vue {
  @Prop() value!: any;
  @Prop(String) label!: string;
  @Prop(Function) textValue!: Function;
  @Prop(Array) items!: Array<FirmwareEntity>;
  @Prop({ type: Function, default: () => {} }) onInput!: Function;
  @Prop({ type: Boolean, default: true }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop(String) versionHWProp!: string;

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }

  get notEmptyRule() {
    return [(v) => !!v || !this.required || this.$t("firmware.fieldrequired")];
  }

  getFirmwareName(item) {
    if (this.textValue) return this.textValue(item);

    let res = item.family;

    if (item.type) res += "_" + item.type;

    if (item.subtype) res += "_" + item.subtype;

    item.versionHW
      ? (res += "_" + "[" + item.versionHW + "]" + "_" + item.versionSW)
      : (res += "_" + item.versionSW);

    return res;
  }

  getFirmwareByHWLatest(item) {
    const hwVersionLatest = item.hwList.find(
      (itemHW) => itemHW.hw === this.versionHWProp
    );

    return hwVersionLatest?.latest;
  }

  public onChange(item) {
    this.$emit("change", item);
  }
}
</script>
