import { Cell as CellExcel } from "exceljs";
import { Cell as CellPdf } from "jspdf-autotable";
import { ExportType, HorizontalAlign } from "./ExportHeaderInterface";

export default class ExportCell {
  private excelCell: CellExcel | null;
  private pdfCell: CellPdf | null;
  constructor(cell: CellExcel | CellPdf) {
    if (this.isExcelCell(cell)) {
      this.excelCell = cell as CellExcel;
      this.pdfCell = null;
    } else {
      this.excelCell = null;
      this.pdfCell = cell as CellPdf;
    }
  }

  private isExcelCell(cell: CellExcel | CellPdf): boolean {
    return (cell as CellExcel).worksheet !== undefined;
  }

  /**
   * Asigna un color al texto de la celda.
   * @param color Color en formato hexadecimal (FFFFFF).
   * @param type Tipo de exportación. Opcional. Puede ser "excel" o "pdf" o "csv".
   */
  public setColor(color: string, type: ExportType | null = null): void {
    if (this.excelCell && (type === null || type === ExportType.EXCEL)) {
      this.excelCell.font = { color: { argb: color } };
    }
    if (this.pdfCell && (type === null || type === ExportType.PDF)) {
      this.pdfCell.styles.textColor = color;
    }
  }

  /**
   * Asigna un color de fondo a la celda.
   * @param color Color en formato hexadecimal (FFFFFF).
   */
  public setBgColor(color: string): void {
    if (this.excelCell) {
      this.excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: color },
      };
    } else if (this.pdfCell) {
      this.pdfCell.styles.fillColor = color;
    }
  }

  /** Asigna un alineamiento horizontal a la celda. */
  public setHorizontalAlign(align: HorizontalAlign): void {
    if (this.excelCell) {
      this.excelCell.alignment = { horizontal: align };
    } else if (this.pdfCell) {
      this.pdfCell.styles.halign = align;
    }
  }
}
