import { Vue, Component } from "vue-property-decorator";
import ApiMonitor from "@/api/monitor";
import { Prop } from "vue-property-decorator";
import { displayErrorMessage } from "@/api";
import { getMarkerIcon, getMarkerIconNewMap } from "../DeviceTabItem";
import TableMarkersMap from "@/components/TableMarkersMap/TableMarkersMap.vue";

@Component({
  components: {
    TableMarkersMap,
  },
})
export default class DeviceTabItem extends Vue {
  @Prop({
    type: String,
    required: true,
    validator: (value: string) => {
      return ["PANEL", "MONITOR"].includes(value);
    },
  })
  readonly type!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() getWithoutPagination!: any;
  @Prop({ type: Boolean, default: false }) hasAnyActiveFilter!: boolean;
  @Prop({ type: String, default: "" }) search!: string;

  public mapLoaders = {
    getDataAreas: this.getDataAreas,
    getDataZones: this.getDataZones,
    getDataSubzones: this.getDataSubzones,
    getData: this.getMapData,
  };

  public mapMarkerInfo = {
    getName: (o) => o.serialNumber,
    getIcon: getMarkerIcon,
    getIconNewMap: getMarkerIconNewMap,
    isOnline: (o) => o.status === true,
  };

  getDataAreas() {
    return ApiMonitor.getGeodataAreas(this.type);
  }

  getDataZones() {
    return ApiMonitor.getGeodataZones(this.type);
  }

  getDataSubzones() {
    return ApiMonitor.getGeodataSubzones(this.type);
  }

  getMapData() {
    return new Promise((resolve, reject) => {
      const state = {
        loaded: null,
        total: null,
        data: [],
      };

      this.loadFullData(this.getWithoutPagination, state)
        .then((res) => {
          state.loaded = null;
          state.total = null;
          resolve(res);
        })
        .catch((err) => {
          displayErrorMessage("geo.error.getDataError", err);

          state.loaded = null;
          state.total = null;
          reject(err);
        });
    });
  }

  private _loadAndPutFullData(petition, page, data, resolve, reject, state) {
    petition(page, 500)
      .then((res) => {
        data = [...data, ...res.data.content];

        state.loaded = page;

        if (res.data.last) return resolve(data);

        let numTotalPages = res.data.totalElements / 500;
        if (numTotalPages % 10 !== 0)
          numTotalPages = Math.floor(numTotalPages) + 1;

        state.total = numTotalPages;

        this._loadAndPutFullData(
          petition,
          page + 1,
          data,
          resolve,
          reject,
          state
        );
      })
      .catch((err) => reject(err));
  }

  private loadFullData(petition, state) {
    state.loaded = 0;
    state.data = [];

    return new Promise((resolve, reject) =>
      this._loadAndPutFullData(petition, 1, state.data, resolve, reject, state)
    );
  }

  public onClose() {
    this.$emit("close");
  }

  public onDisableFilters(status: boolean) {
    this.$emit("disableFilters", status);
  }
}
