import FirmwareVersionV2Interface from "./FirmwareVersionV2Interface";

export default class FirmwareVersionV2Entity
  implements FirmwareVersionV2Interface
{
  fwMajor: string;
  fwMinor: string;
  fwBuild: string;

  constructor(data: FirmwareVersionV2Interface) {
    this.fwMajor = data.fwMajor;
    this.fwMinor = data.fwMinor;
    this.fwBuild = data.fwBuild;
  }

  public getFirmwareVersion() {
    return `${this.fwMajor}.${this.fwMinor}.${this.fwBuild}`;
  }

  /**
   * Obtiene el firmware más alto entre dos versiones.
   */
  public getHighestFirmware(
    firmware: FirmwareVersionV2Entity | null
  ): FirmwareVersionV2Entity {
    if (!firmware) return this;
    if (Number(firmware.fwMajor) > Number(this.fwMajor)) {
      return firmware;
    } else if (Number(firmware.fwMajor) === Number(this.fwMajor)) {
      if (Number(firmware.fwMinor) > Number(this.fwMinor)) {
        return firmware;
      } else if (Number(firmware.fwMinor) === Number(this.fwMinor)) {
        if (Number(firmware.fwBuild) > Number(this.fwBuild)) {
          return firmware;
        }
      }
    }
    return this;
  }

  /**
   * Indica si el firmware actual es mayor que el firmware recibido.
   */
  public isHigherThan(firmware: FirmwareVersionV2Entity): boolean {
    return (
      Number(this.fwMajor) > Number(firmware.fwMajor) ||
      (Number(this.fwMajor) === Number(firmware.fwMajor) &&
        (Number(this.fwMinor) > Number(firmware.fwMinor) ||
          (Number(this.fwMinor) === Number(firmware.fwMinor) &&
            Number(this.fwBuild) > Number(firmware.fwBuild))))
    );
  }

  /**
   * Indica si el firmware actual es mayor o igual que el firmware recibido.
   */
  public isHigherOrEqualThan(
    firmware: FirmwareVersionV2Entity | undefined
  ): boolean {
    if (!firmware) return false;
    return (
      Number(this.fwMajor) > Number(firmware.fwMajor) ||
      (Number(this.fwMajor) === Number(firmware.fwMajor) &&
        (Number(this.fwMinor) > Number(firmware.fwMinor) ||
          (Number(this.fwMinor) === Number(firmware.fwMinor) &&
            Number(this.fwBuild) >= Number(firmware.fwBuild))))
    );
  }

  /**
   * Indica si el firmware actual es menor que el firmware recibido.
   */
  public isLowerThan(firmware: FirmwareVersionV2Entity): boolean {
    return (
      Number(this.fwMajor) < Number(firmware.fwMajor) ||
      (Number(this.fwMajor) === Number(firmware.fwMajor) &&
        (Number(this.fwMinor) < Number(firmware.fwMinor) ||
          (Number(this.fwMinor) === Number(firmware.fwMinor) &&
            Number(this.fwBuild) < Number(firmware.fwBuild))))
    );
  }

  /**
   * Indica si el firmware actual es menor o igual que el firmware recibido.
   */
  /*public isLowerOrEqualThan(
    firmware: FirmwareVersionV2Entity | undefined
  ): boolean {
    if (!firmware) return false;
    return (
      Number(this.fwMajor) < Number(firmware.fwMajor) ||
      (Number(this.fwMajor) === Number(firmware.fwMajor) &&
        (Number(this.fwMinor) < Number(firmware.fwMinor) ||
          (Number(this.fwMinor) === Number(firmware.fwMinor) &&
            Number(this.fwBuild) <= Number(firmware.fwBuild))))
    );
  }*/

  /**
   * Indica si el firmware actual es igual al firmware recibido.
   */
  public isEqual(firmware: FirmwareVersionV2Entity): boolean {
    return (
      Number(this.fwMajor) === Number(firmware.fwMajor) &&
      Number(this.fwMinor) === Number(firmware.fwMinor) &&
      Number(this.fwBuild) === Number(firmware.fwBuild)
    );
  }
}
