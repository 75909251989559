import { HWList } from "@/interfaces/firmwares";
import FirmwareVersionV2Entity from "../Firmware/FirmwareVersionV2Entity";
import FirmwareVersionV2Interface from "../Firmware/FirmwareVersionV2Interface";

export interface FirmwareInterface {
  id: string;
  versionSW: string;
  versionHW: string;
  family: string;
  type: string;
  subtype: string;
  critical: boolean;
  details: string;
  changelog: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  uri: string;
  sha256: string;
  build: string;
  latest: boolean;
  connectable: boolean;
  automaticUpdate: boolean;
  hwList: HWList[];
  firmwareVersionV2: FirmwareVersionV2Interface;
}

const defaultFirmware: FirmwareInterface = {
  id: "",
  versionSW: "",
  versionHW: "",
  family: "",
  type: "",
  subtype: "",
  critical: false,
  details: "",
  changelog: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  status: "",
  uri: "",
  sha256: "",
  build: "",
  latest: false,
  connectable: false,
  automaticUpdate: false,
  hwList: [],
  firmwareVersionV2: {
    fwMajor: "",
    fwMinor: "",
    fwBuild: "",
  },
};

export class FirmwareEntity implements FirmwareInterface {
  id: string;
  versionSW: string;
  versionHW: string;
  family: string;
  type: string;
  subtype: string;
  critical: boolean;
  details: string;
  changelog: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  uri: string;
  sha256: string;
  build: string;
  latest: boolean;
  connectable: boolean;
  automaticUpdate: boolean;
  hwList: HWList[];
  firmwareVersionV2: FirmwareVersionV2Entity;

  constructor(data: FirmwareInterface = defaultFirmware) {
    this.id = data.id;
    this.versionSW = data.versionSW;
    this.versionHW = data.versionHW;
    this.family = data.family;
    this.type = data.type;
    this.subtype = data.subtype;
    this.critical = data.critical;
    this.details = data.details;
    this.changelog = data.changelog;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.status = data.status;
    this.uri = data.uri;
    this.sha256 = data.sha256;
    this.build = data.build;
    this.latest = data.latest;
    this.connectable = data.connectable;
    this.automaticUpdate = data.automaticUpdate;
    this.hwList = data.hwList;
    this.firmwareVersionV2 = new FirmwareVersionV2Entity(
      data.firmwareVersionV2
    );
  }

  /**
   * Método para saber si el listado de HWs que viene como parámetro
   * existe en la lista de HWs del firmware.
   * @param hwList Listado de HWs a comparar.
   * @returns Booleano que indica si existe o no.
   */
  hwListIncludes(hwList: string[]): boolean {
    return this.hwList.some((hw) => hwList.includes(hw.hw));
  }

  /**
   * Método para saber si el HW que viene como parámetro
   * existe en la lista de HWs del firmware.
   * @param hw HW a comparar.
   * @returns Booleano que indica si existe o no.
   */
  hwIncludes(hw: string): boolean {
    return this.hwList.some((hwItem) => hwItem.hw === hw);
  }
}
