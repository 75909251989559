import { Cell, Row, Workbook, Worksheet } from "exceljs";

export default class ExportTableExcel {
  private workbook: Workbook;
  private worksheet: Worksheet;
  private headers: string[] = [];
  private headersWidth: number[] = [];
  private data: string[][] = [];
  private callbackStyles: (
    indexRow: number,
    indexCol: number,
    cell: Cell
  ) => void;
  constructor(
    title: string,
    callbackStyles: (indexRow: number, indexCol: number, cell: Cell) => void
  ) {
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet(title);
    this.callbackStyles = callbackStyles;
  }

  public addHeader(header: string, width = 8.43): void {
    this.headers.push(header);
    this.headersWidth.push(width);
  }

  public addDatum(data: string[]): void {
    this.data.push(data);
  }

  /** Construye y formatea la cabecera del título. */
  private generateHeaderTitle() {
    // Añade una fila con el título.
    const titleRow = this.worksheet.addRow([this.worksheet.name]);
    // Junta las columnas del título.
    this.worksheet.mergeCells(1, 1, 1, this.headers.length);
    // Formatea la fila con el título.
    titleRow.height = 28;
    const titleCell = titleRow.getCell(1);
    titleCell.font = { bold: true, size: 16 };
    titleCell.alignment = { vertical: "middle" };
  }

  /** Contruye y formatea las cabeceras de la hoja de cálculo */
  private generateHeaders() {
    // Añade una fila con las etiquetas de la cabeceras.
    const row: Row = this.worksheet.addRow(this.headers);
    // Formatea la fila con las cabeceras.
    row.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "0070C0" },
      };
      cell.font = { color: { argb: "FFFFFF" } };
    });
    // Ajusta el ancho de las columnas.
    /*this.headersWidth.forEach((width, i) => {
      this.worksheet.getColumn(i + 1).width = width;
    });*/
    // Crear autofiltros en la fila de cabecera.
    this.worksheet.autoFilter = {
      from: { row: 2, column: 1 },
      to: { row: 2, column: this.headers.length },
    };
  }

  private generateBody() {
    const rows: Row[] = this.worksheet.addRows(this.data);
    rows.forEach((row, indexRow) => {
      row.eachCell((cell, indexCol) => {
        this.callbackStyles(indexRow, indexCol - 1, cell);
      });
    });
  }

  private autoWidthColumns() {
    this.data.forEach((datum) => {
      datum.forEach((value, j) => {
        if (
          value &&
          value.length > this.headersWidth[j] &&
          value.length < 130
        ) {
          this.headersWidth[j] = value.length + 2;
        }
      });
    });
    this.headersWidth.forEach((width, i) => {
      this.worksheet.getColumn(i + 1).width = width;
    });
    /*this.worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell((cell) => {
        const length = cell.value ? cell.value.toString().length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });*/
  }

  public async download(fileName: string) {
    this.generateHeaderTitle();
    this.generateHeaders();
    this.generateBody();
    this.autoWidthColumns();

    await this.downloadFile(fileName);
  }

  /** Descarga el archivo Excel con los datos de la tabla. */
  private async downloadFile(fileName: string) {
    // Generar el archivo Excel como un Blob
    await this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace para descargar el Blob
      const enlace = document.createElement("a");
      enlace.download = fileName + ".xlsx";
      enlace.href = url;

      // Simular un clic en el enlace para iniciar la descarga
      enlace.click();

      // Liberar la URL del Blob
      window.URL.revokeObjectURL(url);
    });
  }
}
